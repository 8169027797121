<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  useForwardPropsEmits,
} from 'radix-vue'
import { X } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const props = defineProps<DialogContentProps & { 
  class?: HTMLAttributes['class'], 
  hideCloseButton?: boolean,
  description?: string,
  title: string,
  hideTitle?: boolean
}>()
const emits = defineEmits<DialogContentEmits>()

const dialogDescriptionId = computed(() => `dialog-description-${Math.random().toString(36).slice(2, 11)}`)
const dialogTitleId = computed(() => `dialog-title-${Math.random().toString(36).slice(2, 11)}`)

const delegatedProps = computed(() => {
  const { class: _, description: __, title: ___, hideTitle: ____, ...delegated } = props

  return {
    ...delegated,
    'aria-describedby': props.description ? dialogDescriptionId.value : undefined,
    'aria-labelledby': dialogTitleId.value
  }
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
    <DialogContent v-bind="forwarded" :class="
        cn(
          'fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg -translate-x-[50%] -translate-y-[50%] gap-0 bg-background p-6 shadow-lg duration-200 sm:rounded-lg',
          'data-[state=open]:animate-in data-[state=closed]:animate-out',
          'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          'data-[state=closed]:animate-out data-[state=closed]:animate-zoom-out',
          'data-[state=open]:animate-in data-[state=open]:animate-zoom-in',
          'data-[state=closed]:animate-slide-out-to-top data-[state=open]:animate-slide-in-from-top',
          props.class,
        )">
      <DialogTitle :id="dialogTitleId" :class="{ 'sr-only': props.hideTitle }">
        {{ props.title }}
      </DialogTitle>
      <div v-if="props.description" :id="dialogDescriptionId" class="sr-only">{{ props.description }}</div>
      <slot />

      <DialogClose
        v-if="!props.hideCloseButton"
        class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <X class="w-4 h-4" />
        <span class="sr-only">Close</span>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
