<template>
    <header v-if="!isLoggedOutRoute" ref="headerRef" :class="[
        'flex items-center gap-0 sm:gap-2 header-root border-b !border-muted/10 !bg-transparent w-[calc(100vw)]  sm:w-full min-h-[64px]',
        {
          'pt-1 !h-14': isTeamRoute,
        }
    ]"
    :style="headerStyle">
        <slot name="menu" />
        
        <div class="flex-1" />
        
        <!-- <Button variant="ghost" size="icon" @click="colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'" class="hidden md:block">
            <Icon v-if="colorMode.value === 'dark'" name="material-symbols:light-mode" size="24"/>
            <Icon v-if="colorMode.value === 'light'" name="ph:moon" size="20"/>
        </Button> -->
        <div v-if="!user" class="group relative w-fit flex items-center justify-end flex-col">
            <!-- darkmode button -->
            <Button @click="toggleColorMode" variant="text" class="select-none rounded-full w-12 h-12">
                <Icon v-if="currentColorMode === 'dark'" name="line-md:sunny-filled-loop-to-moon-filled-alt-loop-transition" class="w-4 h-4" />
                <Icon v-else-if="currentColorMode === 'light'" name="line-md:sunny-loop" class="w-4 h-4" />
                <Icon v-else name="ic:baseline-brightness-auto" class="w-4 h-4" />
            </Button>
        </div>
        <slot name="dropdownmenu" />
    </header>
</template>

<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { useUiStore } from '@/stores/uiStore'

const route = useRoute()
const colorMode = useColorMode()
const isDark = computed(() => colorMode.value === 'dark')
const { toggleBottomSheet } = useUiStore()
const user = useSupabaseUser()

const props = defineProps<{
  scrollY: number
}>()

// Add computed property to check if we're on a team route
const isTeamRoute = computed(() => {
  return route.params.team || route.path.includes('/team/')
})

const isLoggedOutRoute = computed(() => {
  const disallowed = ['login', 'register', 'confirm', 'reset-password', 'maintenance']
  const currentPath = route.path || ''
  return disallowed.some(path => currentPath.includes(path))
})

// Add a computed property to get the current color mode
const currentColorMode = computed(() => colorMode.preference)

// Update toggle function to cycle through dark, light, and system
const toggleColorMode = () => {
  if (colorMode.preference === 'dark') {
    colorMode.preference = 'light'
  } else if (colorMode.preference === 'light') {
    colorMode.preference = 'system'
  } else {
    colorMode.preference = 'dark'
  }
}

// Compute header styles based on scroll position
const headerStyle = computed(() => {
  // Team route has fixed height
  if (isTeamRoute.value) {
    return {
      height: '64px',
      padding: '0 2rem'
    }
  }

  // Non-home routes have fixed height
  if (route.path !== '/') {
    return {
      height: '64px',
      padding: '0 2rem',
      backdropFilter: 'blur(24px)'
    }
  }

  // Home route - smooth transition based on scroll
  const maxScroll = 100
  const progress = Math.min(1, props.scrollY / maxScroll)
  
  const height = 180 - (116 * progress) // 180px to 64px
  const horizontalPadding = progress < 0.1 ? '6vw' : '2rem'
  const verticalPadding = progress < 0.1 ? '4vw' : '0'
  
  return {
    height: `${height}px`,
    padding: `${verticalPadding} ${horizontalPadding}`,
    backdropFilter: progress > 0.1 ? 'blur(24px)' : 'none',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
  }
})

</script>

<style scoped>
.header-root {
  will-change: height, padding, backdrop-filter;
}

@media (max-width: 767px) {
  .header-root {
    height: 64px !important;
    padding: 0 1rem !important;
  }
}
</style>


