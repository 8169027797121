import { serverSupabaseUser } from "#supabase/server"
import { useServerAccessControl, useAccessControl, PUBLIC_PATHS } from '@/composables/useAccessControl'
import { defineAsyncComponent, markRaw } from 'vue'

interface ConfirmAccess {
    allowed: boolean
}

interface RegistrationSettings {
    registration_enabled: boolean
}

export default defineNuxtRouteMiddleware(async (to) => {
    const user = useSupabaseUser()
    const supabase = useSupabaseClient()
    const { isPublicPath } = useAccessControl()
    const uiStore = useUiStore()
    const { setBottomDrawerConfig } = uiStore
    
    // Special check for pitch page
    if (to.path === '/pitch') {
        const allowedUserIds = [
            '9c1b101e-64c5-4e80-8d55-434973b117ff',
            'f72e6e38-df13-4817-809f-8080285a3a68'
        ]
        if (!user.value || !allowedUserIds.includes(user.value.id)) {
            return navigateTo('/')
        }
        return
    }

    // Allow access to public paths
    if (isPublicPath(to.path)) {
        return
    }

    // Redirect to login if no user
    if (!user.value) {
        return navigateTo('/login')
    }

    // Check if user is allowed access
    const { data: confirmAccess, error: accessError } = await supabase
        .from('confirm-access')
        .select('allowed')
        .eq('user_id', user.value.id)
        .single() as { data: ConfirmAccess | null, error: any }

    // Check registration settings
    const { data: registrationSettings, error: regError } = await supabase
        .from('registration_settings')
        .select('registration_enabled')
        .single() as { data: RegistrationSettings | null, error: any }

    // If user has explicit access, allow them regardless of registration settings
    if (confirmAccess?.allowed) {
        return
    }

    // Otherwise, check if access should be denied
    const accessDenied = accessError?.code === 'PGRST116' || 
        !confirmAccess?.allowed || 
        regError?.code === 'PGRST116' || 
        !registrationSettings?.registration_enabled

    if (accessDenied) {
        // Show the waitlist bottom drawer
        setBottomDrawerConfig({
            title: '',
            component: markRaw(defineAsyncComponent(() => import('@/components/GetNotified.vue'))),
            props: {
                email: user.value.email || ''
            }
        })
        
        // Stay on current page if public, otherwise go to home
        if (!isPublicPath(to.path)) {
            return navigateTo('/')
        }
    }

    // Check MFA status
    const { data: mfaData } = await supabase.auth.mfa.listFactors()
    const hasMfaEnabled = mfaData?.totp && mfaData.totp.length > 0
    
    if (hasMfaEnabled) {
        // Get current authentication level
        const { data: authLevel } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
        
        // If MFA is enabled but not completed, redirect to confirm page
        if (authLevel?.currentLevel === 'aal1') {
            return navigateTo('/confirm')
        }
    }
})
