import { defineStore } from 'pinia'
import type { 
  Panel, 
  PanelData,
  AvailablePanel,
  PendingPanel,
} from '@/types/projectdock'
import { useMitter } from '#imports'

// Define the type for our connections registry
interface PanelConnectionsRegistry {
  initialized: Record<string, boolean>;
  connections: Record<string, Record<string, boolean>>;
}

// Define the type for recent PDF files
interface RecentPdf {
  url: string
  title?: string
  filename?: string
  timestamp: number
  fileMetadata?: any
}

export const useDockStore = defineStore(
  "DockStore",
  () => {
    // Add the panelConnectionsRegistry to the store
    const panelConnectionsRegistry = ref<PanelConnectionsRegistry>({
      initialized: {},
      connections: {}
    });
    
    // Storage for recent PDF files
    const recentPdfs = ref<RecentPdf[]>([])
    
    const useCache = ref(true)
    const debug = ref(false)
    const dragOver = ref(false)
    const panelDroppedOn = ref<string | null>(null)
    const activePanel = ref("")
    const includeDataInChat = ref(false)
    const selectedLayout = ref(null)
    const lastActivePanel = ref("")
    const startNewLayout = ref(false)
    const panelId_toolUid = ref<Record<string, string>>({})
    const pendingPanels = ref<PendingPanel[]>([])
    const switchToPanel = ref("")
    const saving = ref(false)
    const promptReplacePanel = ref(false)
    const panelToRefresh = ref<string[]>([])
    const availablePanels = ref<AvailablePanel[]>([])
    const defaultApiService = ref<string>('gemini')

    // Add PDF to recent list
    const addRecentPdf = (pdf: Omit<RecentPdf, 'timestamp'>) => {
      // Create new entry with timestamp
      const newEntry = { ...pdf, timestamp: Date.now() }
      
      // Remove existing entry with same URL if exists
      const filtered = recentPdfs.value.filter(item => item.url !== pdf.url)
      
      // Add new entry at the beginning and limit to 10 items
      recentPdfs.value = [newEntry, ...filtered].slice(0, 10)
    }

    // Get recent PDFs
    const getRecentPdfs = () => recentPdfs.value

    const setPromptReplacePanel = (p: boolean) => {
      promptReplacePanel.value = p
    }

    const setDragOver = (p: boolean) => {
      dragOver.value = p
    }

    const setPanelDroppedOn = (p: string | null) => {
      panelDroppedOn.value = p
    }

    const setPanelId_toolUid = (p: any) => {
      panelId_toolUid.value = { ...panelId_toolUid.value, ...p }
    }

    const deletePanelId_toolUid = () => {
      panelId_toolUid.value = {}
    }

    const setActivePanel = (p: any) => {
      if (p !== activePanel.value) {
        activePanel.value = p
      }
    }

    const setLastActivePanel = (p: any) => {
      lastActivePanel.value = p
    }

    const setIncludeDataInChat = (p: boolean) => {
      includeDataInChat.value = p
    }

    const setSelectedLayout = (p: any) => {
      selectedLayout.value = p
    }

    const setStartNewLayout = (p: boolean) => {
      startNewLayout.value = p
    }

    const addPendingPanel = (panelData: PendingPanel) => {
      pendingPanels.value.push(panelData)
    }

    const getPendingPanels = () => {
      const panels = [...pendingPanels.value]
      pendingPanels.value = []
      return panels
    }

    const setSwitchToPanel = (p: string) => {
      switchToPanel.value = p
    }

    const setUseCache = (p: boolean) => {
      useCache.value = p
    }

    const setSaving = (p: boolean) => {
      saving.value = p
    }

    const refreshPanel = (component_name: string) => {
      panelToRefresh.value = [...panelToRefresh.value, component_name]
    }

    const removePanelToRefresh = (component_name: string) => {
      panelToRefresh.value = panelToRefresh.value.filter(name => name !== component_name)
    }

    const clearPanelsToRefresh = () => {
      panelToRefresh.value = []
    }

    const setAvailablePanel = (data: {
      panel_id: string
      tool_uid: string
      metadata?: any
    }) => {
      if (!Array.isArray(availablePanels.value)) {
        availablePanels.value = []
      }
      const index = availablePanels.value.findIndex(
        (panel) => panel.panel_id === data.panel_id
      )
      if (index !== -1) {
        availablePanels.value[index] = { ...data }
      } else {
        availablePanels.value = [...availablePanels.value, data]
      }
    }

    const removeAvailablePanel = (panel_id: string) => {
      availablePanels.value = availablePanels.value.filter(
        panel => panel.panel_id !== panel_id
      )
    }

    const setDebug = (value: boolean) => {
      debug.value = value
    }

    const setDefaultApiService = (service: string) => {
      defaultApiService.value = service
    }

    // Add methods for managing the panel connections registry
    const isPanelInitialized = (panelId: string): boolean => {
      return !!panelConnectionsRegistry.value.initialized[panelId];
    }

    const markPanelInitialized = (panelId: string) => {
      panelConnectionsRegistry.value.initialized[panelId] = true;
    }

    const isConnectionEstablished = (sourcePanelId: string, targetPanelId: string): boolean => {
      return !!panelConnectionsRegistry.value.connections[sourcePanelId]?.[targetPanelId];
    }

    const registerConnection = (sourcePanelId: string, targetPanelId: string) => {
      if (!panelConnectionsRegistry.value.connections[sourcePanelId]) {
        panelConnectionsRegistry.value.connections[sourcePanelId] = {};
      }
      panelConnectionsRegistry.value.connections[sourcePanelId][targetPanelId] = true;
    }

    const clearPanelConnections = (panelId: string) => {
      delete panelConnectionsRegistry.value.connections[panelId];
      delete panelConnectionsRegistry.value.initialized[panelId];
    }

    // Subscribe to panel events
    if (process.client) {
      const mitter = useMitter()

      // Log panel connections if debug is enabled
      mitter.listen('panel:connect', (event) => {
        if (debug.value) console.log('Panel connected:', event.sourcePanelId, 'to', event.targetPanelId)
      })

      // Log panel disconnections if debug is enabled
      mitter.listen('panel:disconnect', (event) => {
        if (debug.value) console.log('Panel disconnected:', event.sourcePanelId, 'from', event.targetPanelId)
      })
    }

    return {
      panelConnectionsRegistry,
      isPanelInitialized,
      markPanelInitialized,
      isConnectionEstablished,
      registerConnection,
      clearPanelConnections,
      recentPdfs,
      addRecentPdf,
      getRecentPdfs,
      useCache,
      debug,
      dragOver,
      panelDroppedOn,
      activePanel,
      includeDataInChat,
      selectedLayout,
      lastActivePanel,
      startNewLayout,
      panelId_toolUid,
      pendingPanels,
      switchToPanel,
      saving,
      promptReplacePanel,
      panelToRefresh,
      availablePanels,
      defaultApiService,
      setDefaultApiService,
      setPromptReplacePanel,
      setDragOver,
      setPanelDroppedOn,
      setPanelId_toolUid,
      deletePanelId_toolUid,
      setActivePanel,
      setLastActivePanel,
      setIncludeDataInChat,
      setSelectedLayout,
      setStartNewLayout,
      addPendingPanel,
      getPendingPanels,
      setSaving,
      setSwitchToPanel,
      clearPanelsToRefresh,
      removePanelToRefresh,
      setAvailablePanel,
      removeAvailablePanel,
      setUseCache,
      setDebug,
      refreshPanel
    }
  },
  {
    persist: {
      pick: ['useCache', 'debug', 'defaultApiService', 'recentPdfs']
      // Added recentPdfs to persist list
    }
  }
)

