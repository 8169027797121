<template>
  <NavigationMenu class="w-full">
    <NavigationMenuList v-if="!route.params.project" class="max-w-screen-2xl mx-auto px-0 sm:px-6 lg:px-8">
      <div class="flex scale-[1] sm:scale-[1] origin-left pl-0 flex items-center  w-full">
        <div class="p-2 sm:block ml-0">
        
          <NuxtLink class="cursor-pointer" to="/">
          
            <img src="/images/logos/superseeded_logo_small.png" class="h-fill w-fill p-0 m-0 max-h-[2.5rem] max-w-[3rem] bg-transparent" />
          
          </NuxtLink>
          <!-- join waitlist button -->
        </div>
        <transition name="fade">
          <Button v-show="scrollY > 500" @click="handleJoin" size="sm" class="sm:hidden ml-2 text-sm font-medium bg-primary text-primary-foreground hover:bg-primary/90 squircle-15">
            {{ canCreateTeam ? 'start now' : 'Join waitlist' }}
          </Button>
        </transition>
        <NavigationMenuItem v-for="menu in props.menuItems" :key="menu.title">
          <NavigationMenuTrigger @click="navigateTo(menu.item.href)" class="mx-0 px-2 sm:mx-1 sm:pl-3 bg-transparent no-chevron !bg-transparent hover:!bg-muted hidden sm:block">
            <!-- <Icon v-if="menu.item?.icon" :name="menu.item.icon" size="1.8em" class="mr-2 menu-icon" :class="{'text-contractor': menu.item.titleClass === 'text-contractor', 'text-designer': menu.item.titleClass === 'text-designer', 'text-grower': menu.item.titleClass === 'text-grower'}" /> -->
            <span class="text-[1em] sm:text-[1.1em] font-semibold uppercase" :class="{'text-contractor': menu.item.titleClass === 'text-contractor', 'text-designer': menu.item.titleClass === 'text-designer', 'text-grower': menu.item.titleClass === 'text-grower'}">{{menu.item.title}}</span>
          </NavigationMenuTrigger>
          <NavigationMenuContent class="z[10000]">
            <div v-if="!menu?.image?.src">
              <ul class="grid w-[400px] max-w-[70vw] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                <li class="row-span-3">
                  <NavigationMenuLink as-child>
                    <a class="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted no-underline outline-none focus:shadow-md"
                      href="/">
                      
                      <NuxtImg v-if="menu?.item?.image?.src" :src="menu?.item?.image?.src" class="h-fill w-fill p-0 m-0 rounded-md" />

                      <!-- <div class="mb-2 mt-4 text-lg font-medium">

                      </div> -->
                      <p class="text-sm leading-tight text-muted-foreground">
                        <!-- some description here -->
                      </p>
                    </a>
                  </NavigationMenuLink>
                </li> 
                <NavbarListItem v-for="subMenu in menu.subMenuItems" :key="subMenu.title" :title="subMenu.title"
                  :href="subMenu.href" :icon="subMenu.icon">
                  {{ subMenu.description }}
                </NavbarListItem>
              </ul>
            </div>
            <div v-else>
              <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                <li class="row-span-3">
                  <NavigationMenuLink as-child v-if="menu.image.title || menu.image.description">
                    <a class="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                      href="/">
                      <img height="350" :src="menu.image.src" class="h-fill w-fill p-0 m-0" />
                      <div class="mb-2 mt-4 text-lg font-medium">
                        {{menu.image.title}}
                      </div>
                      <p class="text-sm leading-tight text-muted-foreground">
                        {{menu.image.description}}
                      </p>
                    </a>
                  </NavigationMenuLink>
                  <img v-else:src="menu.image.src" class="h-fill w-fill p-0 m-0" />
                </li>
                <NavbarListItem v-for="subMenu in menu.subMenuItems.filter(e => !e.image)" :key="subMenu.title"
                  :title="subMenu.title" :href="subMenu.href" :icon="subMenu?.icon">
                  {{ subMenu.description }}
                </NavbarListItem>
              </ul>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>

        <transition name="fade">
          <Button v-show="scrollY > 500" @click="handleJoin" size="sm" class="hidden sm:block ml-2 text-sm font-medium bg-primary text-primary-foreground hover:bg-primary/90 squircle-15">
            {{ canCreateTeam ? 'start now' : 'Join waitlist' }}
          </Button>
        </transition>
        
      </div>
    </NavigationMenuList>
    <div v-else class="max-w-screen-2xl mx-auto pl-4 -mr-6 sm:px-6 sm:-mr-6 lg:px-8 mt-0 pt-0">
      <MyBreadcrumbs />
    </div>
  </NavigationMenu>

</template>

<script setup>
import { navigationMenuTriggerStyle } from '@/components/ui/navigation-menu'
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'

import { GetNotified } from '#components'

useSquircleObserver()

const user = useSupabaseUser()
const { canCreateTeam } = useAccessControl()
const uiStore = useUiStore()
const { toggleBottomSheet, setBottomSheetDynamicComponent, setBottomDrawerConfig } = uiStore
const userStore = useUserStore()


const handleJoin = async () => {
  // If user is not logged in, redirect to login
  if (!user.value) {
    return navigateTo('/login')
  }

  // If user has access, go to dashboard
  if (canCreateTeam.value) {
    return navigateTo('/dashboard')
  }

  // Otherwise show waitlist bottom drawer
  setBottomDrawerConfig({
    title: '',
    component: markRaw(GetNotified),
    props: {
      email: user.value.email || ''
    }
  })
}


const route = useRoute();

const props = defineProps({
  scrollY: {
    type: Number,
    default: 0
  },
  menuItems: {
    type: Array,
  }
})

</script>


<style>
.no-chevron svg {
  display: none;
}

@media (max-width: 640px) { /* Tailwind's breakpoint for 'sm' */
  button svg.lucide-chevron-down-icon {
    display: none !important;
  }
}

div[id^="radix-vue-dropdown-menu-content"] {
  box-shadow: 0px 5.8px 1.4px -13px rgba(0, 0, 0, 0.058),
  0px 10.2px 3.3px -13px rgba(0, 0, 0, 0.078),
  0px 13.7px 6.3px -13px rgba(0, 0, 0, 0.09),
  0px 17.1px 11.2px -13px rgba(0, 0, 0, 0.1),
  0px 22.2px 20.9px -13px rgba(0, 0, 0, 0.113),
  0px 39px 50px -13px rgba(0, 0, 0, 0.15) !important;
  border: none !important;
  /* border-radius: 8rem !important; */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

</style>