<script setup lang="ts">
interface Props {
  color1?: string
  color2?: string
  color3?: string
  color4?: string
  color5?: string
  color6?: string
}

const props = withDefaults(defineProps<Props>(), {
  color1: 'fuchsia',
  color2: 'yellow',
  color3: 'cyan',
  color4: 'green',
  color5: 'blue',
  color6: 'red'
})

// Define color mapping
const colorMap: Record<string, [number, number, number]> = {
  fuchsia: [236, 72, 153],
  yellow: [234, 179, 8],
  cyan: [6, 182, 212],
  green: [34, 197, 94],
  blue: [59, 130, 246],
  red: [239, 68, 68],
  
  // Additional Tailwind colors
  rose: [244, 63, 94],
  pink: [236, 72, 153],
  purple: [168, 85, 247],
  violet: [139, 92, 246],
  indigo: [99, 102, 241],
  sky: [14, 165, 233],
  teal: [20, 184, 166],
  emerald: [16, 185, 129],
  lime: [132, 204, 22],
  amber: [245, 158, 11],
  orange: [249, 115, 22],
  slate: [71, 85, 105]
}

// Get RGB values safely
const getRgbValues = (colorName: string): [number, number, number] => {
  return colorMap[colorName] || [0, 0, 0]
}

// Type-safe color getter
const getColorByIndex = (index: number) => {
  const colorKey = `color${index}` as keyof Props
  return props[colorKey] || 'fuchsia'
}

// Reactive RGB values with proper typing
const colors = reactive({
  color1: computed(() => getRgbValues(props.color1)),
  color2: computed(() => getRgbValues(props.color2)),
  color3: computed(() => getRgbValues(props.color3)),
  color4: computed(() => getRgbValues(props.color4)),
  color5: computed(() => getRgbValues(props.color5)),
  color6: computed(() => getRgbValues(props.color6))
})

// Type-safe color getter for template
const getColor = (i: number) => {
  const colorKey = `color${i}` as keyof typeof colors
  return colors[colorKey]
}
</script>

<template>
  <div :class="$attrs.class">
    <div class="bg-wrap">
      <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
        <defs>
          <radialGradient
            v-for="i in 6"
            :key="i"
            :id="`Gradient${i}`"
            cx="50%"
            cy="50%"
            :fx="`${i * 0.5}%`"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              :dur="`${20 + i * 2}s`"
              values="0%;3%;0%"
              repeatCount="indefinite"
            />
            <stop
              offset="0%"
              :stop-color="`rgba(${getColor(i)[0]}, ${getColor(i)[1]}, ${getColor(i)[2]}, 1)`"
            />
            <stop
              offset="100%"
              :stop-color="`rgba(${getColor(i)[0]}, ${getColor(i)[1]}, ${getColor(i)[2]}, 0)`"
            />
          </radialGradient>
        </defs>
        <rect
          x="13.744%"
          y="1.18473%"
          width="100%"
          height="100%"
          fill="url(#Gradient1)"
          transform="rotate(334.41 50 50)"
        >
          <animate
            attributeName="x"
            dur="20s"
            values="25%;0%;25%"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            dur="21s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="7s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="-2.17916%"
          y="35.4267%"
          width="100%"
          height="100%"
          fill="url(#Gradient2)"
          transform="rotate(255.072 50 50)"
        >
          <animate
            attributeName="x"
            dur="23s"
            values="-25%;0%;-25%"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            dur="24s"
            values="0%;50%;0%"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="12s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="9.00483%"
          y="14.5733%"
          width="100%"
          height="100%"
          fill="url(#Gradient3)"
          transform="rotate(139.903 50 50)"
        >
          <animate
            attributeName="x"
            dur="25s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            dur="12s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="360 50 50"
            to="0 50 50"
            dur="9s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>
</template>

<style>
.bg-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-wrap svg {
  width: 100%;
  height: 100%;
}
</style>
