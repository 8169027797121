<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/uiStore'

interface AlertConfig {
  title: string
  description?: string
  html?: string
  confirmText?: string
  cancelText?: string | null
  destructive?: boolean
  inputRequired?: boolean
  inputPlaceholder?: string
  onMount?: () => void
  component?: Component
  componentProps?: Record<string, any>
  loading?: boolean
  preventClickOutside?: boolean
}

const uiStore = useUiStore()
const { alertConfig } = storeToRefs(uiStore)
const inputText = ref('')

const handleAction = (action: 'confirm' | 'cancel', text?: string) => {
  uiStore.handleAlertAction(action, text)
}

// Add click outside handler
const handleClickOutside = () => {
  if (alertConfig.value && !alertConfig.value.preventClickOutside) {
    uiStore.closeAlert()
  }
}

// Add escape key handler
const handleEscape = (e: KeyboardEvent) => {
  if (e.key === 'Escape' && alertConfig.value) {
    uiStore.closeAlert()
  }
}

onMounted(() => {
  if (alertConfig.value?.onMount) {
    alertConfig.value.onMount()
  }
  // Add event listeners
  window.addEventListener('keydown', handleEscape)
})

onUnmounted(() => {
  // Remove event listener
  window.removeEventListener('keydown', handleEscape)
})
</script>

<template>
  <AlertDialogContent 
    @pointerDownOutside="handleClickOutside"
    @interactOutside="handleClickOutside"
  >
    <AlertDialogHeader>
      <AlertDialogTitle 
        class="pointer-events-none text-left" 
        :class="[{ 'text-destructive': Boolean(alertConfig?.destructive) }]"
      >
        {{ alertConfig?.title }}
      </AlertDialogTitle>
      <AlertDialogDescription class="pointer-events-auto max-w-[800px]">
        <template v-if="alertConfig?.component">
          <component 
            :is="alertConfig.component"
            v-bind="alertConfig.props || {}"
            v-on="alertConfig.listeners || {}"
          />
        </template>
        <template v-else-if="alertConfig?.html">
          <div v-html="alertConfig.html"></div>
        </template>
        <template v-else>
          {{ alertConfig?.description }}
        </template>
        <slot name="description-extra"></slot>
        <div v-if="alertConfig?.inputRequired" class="mt-4">
          <Input 
            type="text" 
            v-model="inputText" 
            :placeholder="alertConfig?.inputPlaceholder" 
            class="w-full" 
          />
        </div>
      </AlertDialogDescription>
    </AlertDialogHeader>
    <AlertDialogFooter>
      <Button 
        v-if="alertConfig?.cancelText" 
        class="cursor-pointer" 
        variant="ghost"
        @click="handleAction('cancel')"
      >
        {{ alertConfig?.cancelText || 'Cancel' }}
      </Button>
      <Button 
        v-if="alertConfig?.confirmText" 
        class="cursor-pointer" 
        :variant="alertConfig?.destructive ? 'destructive' : 'default'"
        :disabled="alertConfig?.inputRequired && !inputText"
        @click="handleAction('confirm', inputText)"
      >
        <Icon 
          v-if="alertConfig?.loading" 
          name="lucide:loader-2" 
          class="w-4 h-4 mr-2 animate-spin"
        />
        {{ alertConfig?.confirmText }}
      </Button>
    </AlertDialogFooter>
  </AlertDialogContent>
</template>

<style>
/* Add global styles to override the default AlertDialog width */
[role="alertdialog"] {
  max-width: 800px !important;
  width: 90vw !important;
}

/* Ensure content doesn't overflow */
.AlertDialogContent {
  max-height: 90vh;
  overflow-y: auto;
}

/* Add some padding for better spacing */
.AlertDialogDescription {
  padding: 1rem 0;
}
</style>
