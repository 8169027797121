import { useLicense } from "@vue-pdf-viewer/viewer";

// Only provide the function, don't apply the license here
export default defineNuxtPlugin(() => {
  return {
    provide: {
      useLicense: (key: string) => {
        console.log('Applying license via plugin');
        return useLicense(key);
      },
    },
  };
});
