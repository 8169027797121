import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/vue';
import RemoteSources from "@uppy/remote-sources";
import Tus from '@uppy/tus';
import { useUiStore } from '@/stores/uiStore.js';

let uppyInstance: Uppy | null = null;

export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia;
  const uppyStore = useUppyStore(pinia);
  const uiStore = useUiStore();
  const { fileUploadContext } = storeToRefs(uiStore);
  const { setFileUploadSuccess, setFilesAdded, setUppy, resetUploads } = uppyStore;

  // Clean up existing instance if it exists
  if (uppyInstance) {
    uppyInstance.close();
    uppyInstance = null;
  }

  uppyInstance = new Uppy({
    debug: true,
    autoProceed: true,
    onBeforeFileAdded: () => true,
    onBeforeUpload: (files) => {
      Object.values(files).forEach(file => {
        if (!file?.meta?.content_type) {
          file.meta.content_type = file.type
          file.meta.contentType = file.type
          file.meta.panel_id = fileUploadContext.value?.panel_id
        }
      });
      return files;
    }
  })
    .use(Tus, {
      endpoint: 'https://secure.superseeded.ai/files',
      retryDelays: [0, 1000, 3000, 5000],
      chunkSize: 50 * 1024 * 1024, // 50MB chunks
      allowedMetaFields: true,
      onBeforeRequest(req: any) {
        const file = req.file;
        if (!file) return;
        // console.log('file', file);
        // Set proper metadata for tusd
        const meta = {
          filename: file.name,
          type: file.type,
          contentType: file.type,
          size: file.size.toString(),
          panel_id: fileUploadContext.value?.panel_id || file.meta?.panel_id
        };
        
        // Set Upload-Metadata header in tusd format
        const metaPairs = Object.entries(meta)
          .filter(([_, value]) => value != null)
          .map(([key, value]) => `${key} ${btoa(String(value))}`);

        // console.log('file.type', file.type);
        req.setHeader('Content-Type', file.type);
        req.setHeader('Upload-Metadata', metaPairs.join(','))
        // console.log('uppy: onBeforeRequest', req);
        return req;
      },
      onAfterResponse(req: any, res: any) {
        // console.log('uppy: onAfterResponse', req, res);
        // Get the file URL from the Location header
        const location = res.getHeader('Location');
        if (location) {
          const fileId = location.split('/').pop();
          req.fileUrl = `https://secure.superseeded.ai/files/${fileId}`;
          // Store the panel_id from meta in the file object for later reference
          if (req.file && req.file.meta && fileUploadContext.value?.panel_id) {
            req.file.meta.panel_id = fileUploadContext.value.panel_id;
          }
        }
      },
      onError: function (err: Error) {
        console.error("Tus error:", err);
      },
    })
    .use(RemoteSources, {
      companionUrl: "https://secure.superseeded.ai/companion",
      companionCookiesRule: 'include',
      sources: [
        "Dropbox",
        "GoogleDrive",
        "GooglePhotos",
        "OneDrive",
        "Box",
        "Url",
      ],
    });

  // Add cleanup handlers
  uppyInstance.on('cancel-all', () => {
    resetUploads();
  });

  // Handle manual reset
  const reset = () => {
    if (uppyInstance) {
      uppyInstance.cancelAll();
      resetUploads();
    }
  };

  uppyInstance.on('upload-success', (file: any, response: any) => {
    if (!file) return;
    
    // Get the file URL from the response
    const location = response.uploadURL;
    if (!location) return;
    
    const fileId = location.split('/').pop();
    const fileUrl = `https://secure.superseeded.ai/files/${fileId}`;
    
    console.log('Uppy upload success:', {
      fileId,
      panelId: fileUploadContext.value?.panel_id || file.meta?.panel_id,
      fileName: file.name,
      metaKeys: Object.keys(file.meta || {})
    });
    
    // Include panel_id from fileUploadContext or file.meta if available
    const panel_id = fileUploadContext.value?.panel_id || file.meta?.panel_id;
    
    // Construct metadata object with panel_id
    const metadata = {
      ...file.meta,
      panel_id,
      upload_id: file.id,
      url: fileUrl
    };
    
    // Include collection_id from fileUploadContext if available
    if (fileUploadContext.value?.collection_id) {
      metadata.collection_id = fileUploadContext.value.collection_id;
    }
    
    // Ensure setFileUploadSuccess is called with all necessary data
    setFileUploadSuccess({
      source: 'uppy',
      file: {
        ...file,
        id: fileId,
        meta: metadata,
        panel_id, // Add panel_id here for easier access
        name: file.name,
        type: file.type,
        size: file.size
      },
      response: {
        ...response,
        panel_id, // Add panel_id here too for consistency
        uploadURL: fileUrl,
        metadata
      }
    });
  });

  uppyInstance.on('files-added', (files) => {
    setFilesAdded(files);
  });

  uppyInstance.on("file-added", (file) => {
    if (!file || !uppyInstance) return;
    
    // console.log('file-added', file);

    uppyInstance.setFileMeta(file.id, {
      filename: file.name,
      type: file.type,
      content_type: file.type,
      contentType: file.type,
      size: file.size
    });
  });

  // Cleanup on page unload
  if (process.client) {
    window.addEventListener('beforeunload', () => {
      if (uppyInstance) {
        uppyInstance.cancelAll();
      }
    });
  }

  setUppy(uppyInstance);
  nuxtApp.vueApp.component("Dashboard", Dashboard);
  nuxtApp.provide('uppy', uppyInstance);
  
  // Expose reset method
  return {
    provide: {
      uppyReset: reset
    }
  }
});