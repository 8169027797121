<template>
  <div
    class="cursor-grab relative bg-background/80"
    :class="[$attrs.class, 
      cn(
        'squircle-15 relative mx-auto min-h-fit w-full cursor-pointer overflow-hidden p-3',
        'transition-all duration-200 ease-in-out hover:scale-[103%]',
        'shadow-lg backdrop-blur-sm'
      )]"
    @click="onNotificationClick"
  >
    <GradientBackground 
      class="absolute inset-0 opacity-40" 
      :color1="mainColor"
      :color2="complementaryColor1"
      :color3="complementaryColor2"
    />
    <div class="relative z-10 flex flex-row items-start">
      <div class="flex mr-3 items-center justify-start">
        <Icon :name="currentIcon" class="w-5 h-5 max-w-5 max-h-5 mt-1 text-primary" />
      </div>
      <div class="flex flex-col overflow-hidden">
        <div
          class="flex flex-row items-center whitespace-pre font-medium text-primary"
        >
          <span class="text-sm tracking-tight select-none">{{ item?.title }}</span>
          <span class="mx-1 select-none text-primary">·</span>
          
          <span class="text-xs select-none opacity-75 font-[300] select-none text-primary">{{ formattedTimeRef }}</span>
        </div>
        <p class="text-sm font-normal text-primary select-none" v-html="item?.message"></p>
        <div v-if="item?.hasActions" class="flex flex-row items-center">
          <button v-for="action in item?.actions" :key="action.label" @click="action.click" class="text-xs font-medium text-primary hover:text-primary/75 transition-colors duration-200">
            {{ action.label }}
          </button>

        </div>
      </div>
    </div>
    <button
      v-if="item?.dismissible"
      @click.stop="handleDismiss"
      class="absolute top-2 right-2 z-10 text-gray-700 hover:text-gray-900"
    >
      <Icon name="solar:close-circle-linear" class="w-4 h-4" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { cn } from "~/lib/utils";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNotivue } from 'notivue';
import { useIntervalFn } from '@vueuse/core';
import { useNotifications } from 'notivue'

dayjs.extend(relativeTime);

useSquircleObserver()

const cm = computed(() => {
  return useColorMode()
})

interface Item {
  title: string;
  message: string;
  dismissible?: boolean;
  onClick?: () => void;
  onDismiss?: () => void;
  promise?: Promise<any>;
  timeout?: number;
  icon?: string;
  type?: 'success' | 'error' | 'warning' | 'info' | 'promise';
  panel_id?: string;
  hasActions?: boolean;
  actions?: { label: string; click: () => void }[];
}

interface NotificationProps {
  item?: Item;
  time?: string | Date;
  icon?: string;
  color?: string;
  timeout?: number;
}

const props = withDefaults(defineProps<NotificationProps>(), {
  icon: 'solar:check-circle-linear',
  color: 'green',
  time: () => new Date(),
  panel_id: null
});

const notivue = useNotivue();
const dismiss = (item: any) => notivue.dismiss?.(item);
const notify = (options: any) => notivue.notify?.(options);

const formattedTimeRef = ref(
  typeof props.time === 'string' && !dayjs(props.time).isValid()
    ? props.time
    : dayjs(props.time).fromNow()
);

const updateInterval = useIntervalFn(() => {
  if (typeof props.time !== 'string' || dayjs(props.time).isValid()) {
    formattedTimeRef.value = dayjs(props.time).fromNow();
  }
}, 60000);

const onNotificationClick = () => {
  if (props.item?.onClick) {
    props.item.onClick();
  }
};

const handleDismiss = () => {
  if (props.item) {
    dismiss(props.item);
    if (props.item.onDismiss) {
      props.item.onDismiss();
    }
  }
};

// Update color mapping to match GradientBackground's available colors
const colorPalettes = {
  success: ['green', 'cyan'],
  error: ['red', 'amber'],
  warning: ['yellow', 'red'],
  info: ['blue', 'cyan'],
  promise: ['pink', 'blue'],
  default: ['blue', 'cyan']
} as const;

// Update icon mapping based on type
const iconMapping = {
  success: 'solar:check-circle-linear',
  error: 'solar:danger-circle-linear',
  warning: 'solar:shield-warning-outline',
  info: 'solar:info-circle-linear',
  promise: 'line-md:loading-loop'
} as const;

// Update currentIcon logic
const currentIcon = computed(() => {
  if (props.item?.promise) {
    return 'line-md:loading-loop';
  }
  return props.item?.icon || iconMapping[props.item?.type as keyof typeof iconMapping] || props.icon;
});

// Update color computations
const getColorPalette = () => {
  if (props.item?.type) {
    return colorPalettes[props.item.type] || colorPalettes.default;
  }
  return colorPalettes.default;
};

const complementaryColor1 = computed(() => getColorPalette()[0]);
const complementaryColor2 = computed(() => getColorPalette()[1]);
const mainColor = computed(() => complementaryColor1.value);
</script>
