<template>
  <div class="min-h-screen w-full bg-background">
    <ClientOnly> 
        <div v-if="isNavbarVisible && !isAuthLayout" class="z-30 relative w-full z-10 navbar-sticky bg-background" >
          
          <NavbarHeader ref="navbarHeader" v-motion-fade class="">
            <template #menu>
              <div class="w-full">
                <NavbarMenu :menuItems="menu" />
              </div>
            </template>

            <template #dropdownmenu>
              <NavbarDropdownMenu v-if="user" />

            </template>
          </NavbarHeader>
        </div>
      </ClientOnly>
      
  <div class="flex items-center justify-center bg-background overflow-hidden min-h-[calc(100vh-64px)]">
    <div class="text-center">
      <h1 class="text-4xl font-bold mb-4">{{ error?.statusCode }}</h1>
      <p class="mb-4">{{ error?.message || 'Something went wrong' }}</p>
      <button 
      variant="ghost"
        @click="handleError"
        class="px-4 py-2 rounded-full"
      >
        Try Again
      </button>
    </div>
  </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  error: Object
})

definePageMeta({
  layout: 'default',
  hideFooter: true
})

const handleError = () => clearError({ redirect: '/' })

// Add required state from app.vue
const user = useSupabaseUser()
const supabase = useSupabaseClient()
const route = useRoute()
const uiStore = useUiStore()
const { menu } = storeToRefs(uiStore)

const isNavbarVisible = ref(false)

const isAuthLayout = computed(() => {
  return route.meta.layout === 'auth'
})

// Function to check auth status
const checkAuthStatus = async () => {
  try {
    const { data: { user: authenticatedUser } } = await supabase.auth.getUser()
    isNavbarVisible.value = !!authenticatedUser || route.path === '/'
  } catch (e) {
    console.error('Auth check error:', e)
    isNavbarVisible.value = route.path === '/'
  }
}

// Initial check
checkAuthStatus()

// Watch for route changes
watch(() => route.path, () => {
  checkAuthStatus()
})

// Set up auth state change listener
onMounted(() => {
  const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
    checkAuthStatus()
  })

  // Cleanup subscription
  onUnmounted(() => {
    subscription.unsubscribe()
  })
})
</script>
