<script setup lang="ts">
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useUserStore } from '@/stores/userStore'
import { useProjectData } from '@/composables/projectData'
import avatar from 'gradient-avatar'
import TeamMemberManagement from './TeamMemberManagement.vue'
import { useUiStore } from '@/stores/uiStore'
import { useSupabaseUser } from '#imports'
import { v4 as uuidv4 } from 'uuid'


const uiStore = useUiStore()

const user = useSupabaseUser()

const userStore = useUserStore()
const { selectedTeam } = storeToRefs(userStore)
const { getTeamMembersByGroupId } = useProjectData()

// Add these interfaces at the top of the script
interface TeamMember {
  id: string
  user_id: string
  email: string
  avatar_url?: string
  full_name?: string
}

interface InviteResponse {
  data: {
    encrypted: string[]
  }
}

// Update the AlertConfig interface to include the properties we need
interface AlertConfig {
  title: string
  description: string
  component?: any
  loading?: boolean
  [key: string]: any // Allow additional properties
}

// Update the teamMembers ref type
const { data: teamMembers } = await getTeamMembersByGroupId(
  selectedTeam.value?.id || '', 
  'id,user_id,avatar_url,full_name,email'
)

// Calculate how many additional members to show in the +X indicator
const maxVisibleAvatars = 5
const additionalMembers = computed(() => {
  if (!teamMembers.value) return 0
  return Math.max(0, teamMembers.value.length - maxVisibleAvatars)
})

// State for invite form
const invitedUsers = ref<string[]>([])
const isInviting = ref(false)

// Update the interface and state for invite links
interface InviteLink {
  link: string;
  code: string;
}

// Update state definitions
const inviteLinks = ref<InviteLink[] | null>(null)
const sendingInvitations = ref('')

// Separate function for sending invites
const sendInvitations = async (encryptedLinks: string[]) => {
  sendingInvitations.value = 'pending'
  
  try {
    const { data, error, status } = await useFetch('/api/invite/send', {
      method: 'POST',
      body: {
        team_name: selectedTeam.value?.metadata?.name,
        invited_users: invitedUsers.value,
        invite_links: encryptedLinks,
      }
    })

    sendingInvitations.value = 'sent'

    if (status.value === 'success') {
      sendingInvitations.value = 'delivered'
      
      // Clear form
      invitedUsers.value = []
      
      push.success({
        title: 'Success!',
        message: `Successfully sent invites to ${invitedUsers.value.length} users`
      })
    }
  } catch (error) {
    console.error('Error sending invites:', error)
    push.error({
      title: 'Error',
      message: 'Failed to send invites. Please try again.'
    })
    sendingInvitations.value = ''
  }
}

// Update generate invite function to use sendInvitations
const generateInvite = async () => {
  if (!invitedUsers.value.length || !selectedTeam.value?.id) return
  
  try {
    // Check if users are already team members
    const { data: existingMembers } = await getTeamMembersByGroupId(
      selectedTeam.value.id,
      'email'
    )
    
    // Filter out already invited users
    const existingEmails = existingMembers.value?.map((m: any) => m.email.toLowerCase()) || []
    const newInvites = invitedUsers.value.filter(email => 
      !existingEmails.includes(email.toLowerCase())
    )

    if (!newInvites.length) {
      push.info({
        title: 'Notice',
        message: 'All users are already team members.'
      })
      return
    }

    // Use new invite endpoint
    const { data: inviteData } = await useFetch<InviteResponse>('/api/groups/invite', {
      method: 'POST',
      body: {
        group_id: selectedTeam.value.id,
        group_name: selectedTeam.value.metadata?.name,
        invited_users: newInvites.map(email => ({ 
          id: uuidv4(),
          email 
        }))
      }
    })

    if (!inviteData.value?.data?.encrypted) {
      throw new Error('Failed to generate invite links')
    }

    // Transform encrypted data into InviteLink format
    const encryptedLinks = inviteData.value.data.encrypted
    inviteLinks.value = Array.isArray(encryptedLinks) ? encryptedLinks.map((code: string) => ({
      link: `${window.location.origin}/invite/${code}`,
      code
    })) : null

    // Send invites using separate function
    if (encryptedLinks) {
      await sendInvitations(encryptedLinks)
    }

  } catch (error) {
    console.error('Error generating invites:', error)
    push.error({
      title: 'Error',
      message: 'Failed to generate invites. Please try again.'
    })
  } finally {
    if (sendingInvitations.value !== 'delivered') {
      sendingInvitations.value = ''
    }
    isInviting.value = false
  }
}

const isOpen = ref(false)
const triggerRef = ref<HTMLElement | null>(null)

const handleOpenChange = (open: boolean) => {
  isOpen.value = open
}

// Add click handler for the trigger
const handleTriggerClick = () => {
  isOpen.value = !isOpen.value
}

// Helper function for email initial
const getInitial = (email?: string) => email?.charAt(0).toUpperCase() || ''

const isUpdating = ref(false)

const showManageMembers = () => {
  const uiStore = useUiStore()
  uiStore.setAlertConfig({
    title: 'Team Members',
    description: '',
    component: markRaw(TeamMemberManagement), 
    componentProps: {
      members: teamMembers.value,
      groupId: selectedTeam.value?.id,
      onClose: () => uiStore.closeAlert(),
      'onUpdate:loading': (loading: boolean) => isUpdating.value = loading
    },
    loading: isUpdating.value
  } as AlertConfig)
}

// Watch for loading changes and update the alert config
watch(isUpdating, (newValue) => {
  if (uiStore.alertConfig) {
    uiStore.setAlertConfig({
      ...(uiStore.alertConfig as AlertConfig),
      loading: newValue
    } as AlertConfig)
  }
})
</script>

<template>
  <NavigationMenu 
    v-if="selectedTeam" 
    class="relative"
    :disableHoverTrigger="false"
    v-model:open="isOpen"
    @update:open="handleOpenChange"
    rounded="2xl"
  >
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger 
          ref="triggerRef"
          class="px-2 py-1 h-9 flex items-center gap-2 bg-background/50 hover:bg-background data-[state=open]:bg-background squircle-10"
          @click="handleTriggerClick"
        >
          <div class="flex items-center gap-2">
            <Avatar class="h-6 w-6">
              <AvatarImage 
                :src="`https://avatar.vercel.sh/${selectedTeam?.metadata?.name}.png`" 
                :alt="selectedTeam?.metadata?.name"
              />
              <AvatarFallback>{{ selectedTeam?.metadata?.name?.charAt(0) }}</AvatarFallback>
            </Avatar>
            <span>{{ selectedTeam.metadata?.name }}</span>
          </div>
        </NavigationMenuTrigger>
        <NavigationMenuContent 
          class="z-50"
          :forceMount="false"
          rounded="2xl"
        >
          <div class="w-[400px] p-4 bg-popover border rounded-md shadow-md">
            <!-- Team Members Section -->
            <div class="space-y-4">
              <div class="flex items-center justify-between text-neutral-600">
                <h3 class="text-sm font-regular">Team Members</h3>
                <Button variant="ghost" size="sm" class=" bg-muted-foreground/20 hover:bg-muted-foreground/10 text-neutral-600 hover:text-neutral-600" @click="showManageMembers">
                  <Icon name="ph:users" class="w-4 h-4 mr-2" />
                  <span class="text-sm font-[300]">Manage</span>
                </Button>
              </div>
              
              <!-- Updated Member Avatars -->
              <div class="flex -space-x-2 overflow-hidden">
                <div 
                  v-for="member in teamMembers?.slice(0, maxVisibleAvatars)" 
                  :key="(member as TeamMember).id"
                  class="relative"
                >
                  <div 
                    :class="[
                      'w-8 h-8 flex flex-cols justify-center items-center masked', 
                      `bg-[url('https://avatar.vercel.sh/${(member as TeamMember).email}.png')]`
                    ]"
                  >
                    <ClientOnly>
                      <div 
                        class="top-0 left-0 w-full h-full" 
                        v-html="avatar((member as TeamMember).email || '', 32)"
                      />
                    </ClientOnly>
                    <p class="absolute text-sm text-white">
                      {{ getInitial((member as TeamMember).email) }}
                    </p>
                  </div>
                </div>
                
                <!-- Additional members indicator -->
                <div 
                  v-if="additionalMembers > 0"
                  class="flex items-center justify-center h-8 w-8 rounded-full bg-muted text-muted-foreground ring-2 ring-background"
                >
                  <span class="text-xs font-medium">+{{ additionalMembers }}</span>
                </div>
              </div>
            </div>

            <Separator class="my-4" />

            <!-- Invite Section -->
            <div class="space-y-2">
              <h3 class="text-sm font-regular">Invite others</h3>
              
              <div class="flex gap-2">
                <div class="flex-1">
                  <TagsInput 
                    :delimiters="[32]" 
                    :addOnTab="true"
                    :addOnPaste="true" 
                    v-model="invitedUsers"
                  >
                    <TagsInputItem 
                      v-for="item in invitedUsers" 
                      :key="item" 
                      :value="item"
                    >
                      <TagsInputItemText />
                      <TagsInputItemDelete />
                    </TagsInputItem>
                    <TagsInputInput :placeholder="invitedUsers.length > 0 ? '' : 'Email addresses ← Tab/Enter to add more'" />
                  </TagsInput>
                </div>

                <Button 
                  @click="generateInvite"
                  class="text-neutral-600 bg-muted-foreground/20 hover:bg-muted-foreground/10"
                  :disabled="isInviting || !invitedUsers.length"
                >
                  <Icon 
                    v-if="sendingInvitations === 'pending'"
                    name="lucide:loader-2" 
                    class="w-4 h-4 mr-2 animate-spin"
                  />
                  <Icon 
                    v-else-if="sendingInvitations === ''"
                    name="lucide:user-plus" 
                    class="w-4 h-4 mr-2"
                  />
                  <Icon 
                    v-else-if="sendingInvitations === 'sent'"
                    name="ic:round-done" 
                    class="w-4 h-4 mr-2 text-muted-foreground"
                  />
                  <Icon 
                    v-else-if="sendingInvitations === 'delivered'"
                    name="material-symbols:done-all-rounded" 
                    class="w-4 h-4 mr-2 text-sky-400"
                  />
                  {{ sendingInvitations === 'pending' ? 'Sending...' : 
                     sendingInvitations === 'sent' ? 'Sent' :
                     sendingInvitations === 'delivered' ? 'Delivered' : 
                     'Send' }}
                </Button>
              </div>
              
              <inviteLinks 
                v-if="inviteLinks" 
                :invites="markRaw(inviteLinks)" 
              />
            </div>
          </div>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
</template>

<style scoped>
.navigation-menu {
  position: relative;
}

:deep(.navigation-menu-content) {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 50;
}

:deep(.navigation-menu-content[data-state='open']) {
  animation: scaleIn 0.2s ease;
}

:deep(.navigation-menu-content[data-state='closed']) {
  animation: scaleOut 0.2s ease;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

:deep(.navigation-menu-trigger) {
  background: transparent;
  border: none;
}

:deep(.navigation-menu-trigger:hover) {
  background: transparent;
}

:deep(.navigation-menu-trigger[data-state='open']) {
  background: transparent;
}

.masked {
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-repeat: no-repeat;
  mask-size: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
}
</style>