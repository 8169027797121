import revive_payload_client_SyFvudjDW0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vue_app_config_DRZl0lYez1 from "/vercel/path0/.nuxt/vue-app-config.mjs";
import unhead_R2XaMJgdUe from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qF9l6f1Ryj from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_4C8Erk5HUQ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_NVZh1T3TWz from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.0_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_lRIV5jvWvn from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_axGeyExSd1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g6OIFAGfE9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GTpBvMlv7H from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fCebrjpjh2 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_TXWEeGhtXx from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eZujz8SZgH from "/vercel/path0/node_modules/.pnpm/nuxt-echarts@0.2.4_echarts@5.6.0_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_xuilZ67r4n from "/vercel/path0/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.13_typescript@5.7.3_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_xhQucnHIpZ from "/vercel/path0/node_modules/.pnpm/nuxt-split-type@0.1.8_magicast@0.3.5_nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@p_omo2hpskhnvnweteg7kohfgfr4/node_modules/nuxt-split-type/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/vercel/path0/.nuxt/gsapPlugin.mjs";
import plugin_AY2LKbRPV8 from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.32.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import mitterPlugin_7o1kq7Af92 from "/vercel/path0/node_modules/.pnpm/nuxt-mitter@1.1.3_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-mitter/dist/runtime/mitterPlugin.js";
import anchorscroll_plugin_tURrw4tB2p from "/vercel/path0/node_modules/.pnpm/nuxt-anchorscroll@1.0.5_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.js";
import _001_notivue_client_bE2FHKqHiu from "/vercel/path0/.nuxt/001.notivue.client.mjs";
import motion_MbmVjsAnhb from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_client_O82MEhe1N4 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import auth_redirect_oZ7X7bJSWr from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import cookiebot_plugin_ZvcVAUHuwL from "/vercel/path0/node_modules/.pnpm/@weareheavy+nuxt-cookie-consent@1.0.4_magicast@0.3.5_rollup@4.32.1/node_modules/@weareheavy/nuxt-cookie-consent/dist/runtime/plugins/cookiebot.plugin.mjs";
import siteConfig_G9eIb33fMz from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_Iry4UXOpFX from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_ky8Wa2oS6E from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_tWq9saXzrj from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typesc_4zlwkixszfnzvfigkplgvtrqba/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _0_22_drag_select_midNNg2mzb from "/vercel/path0/plugins/0.22.drag-select.ts";
import _0_24_Vue3Lottie_client_nF1VBylm6x from "/vercel/path0/plugins/0.24.Vue3Lottie.client.ts";
import _0_94_plyr_client_b751RLv0Mr from "/vercel/path0/plugins/0.94.plyr.client.ts";
import _0_95_scattergl_client_YbTedD7arR from "/vercel/path0/plugins/0.95.scattergl.client.ts";
import _0_95_vercel_analytics_client_AHihIkYZm2 from "/vercel/path0/plugins/0.95.vercel-analytics.client.ts";
import _010_sentry_client_qtk3FqHvvX from "/vercel/path0/plugins/010.sentry.client.ts";
import _010_vue_code_layout_client_tAsBXuI5pR from "/vercel/path0/plugins/010.vue-code-layout.client.ts";
import _012_vue_photo_preview_client_xt8Txlkm6Q from "/vercel/path0/plugins/012.vue-photo-preview.client.ts";
import _020_ag_grid_client_CBw0bhLalg from "/vercel/path0/plugins/020.ag-grid.client.ts";
import _020_fit2box_client_7dQ8xfNgoy from "/vercel/path0/plugins/020.fit2box.client.ts";
import _021_vueuse_gesture_5lK55OQwFQ from "/vercel/path0/plugins/021.vueuse.gesture.ts";
import _023_json_viewer_client_CwNwUoGvLp from "/vercel/path0/plugins/023.json-viewer.client.ts";
import _030_uplot_client_sOHiDendNY from "/vercel/path0/plugins/030.uplot.client.ts";
import _040_auto_animate_client_kSN6cGeXRu from "/vercel/path0/plugins/040.auto-animate.client.ts";
import _070_uppy_client_yepT13vxoE from "/vercel/path0/plugins/070.uppy.client.ts";
import _080_error_handler_pzY4GmDTGp from "/vercel/path0/plugins/080.error-handler.ts";
import _091_vgrid_client_lex8Ugz66y from "/vercel/path0/plugins/091.vgrid.client.ts";
import _093_useLicense_client_ZxTFj52SQu from "/vercel/path0/plugins/093.useLicense.client.ts";
import authNavGuard_client_5VQHzXtSXe from "/vercel/path0/plugins/authNavGuard.client.ts";
import vue_code_layout_client_PIvC8TjfHX from "/vercel/path0/plugins/vue-code-layout.client.ts";
import defaults_GshYTuZiKk from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_SyFvudjDW0,
  vue_app_config_DRZl0lYez1,
  unhead_R2XaMJgdUe,
  router_qF9l6f1Ryj,
  supabase_client_4C8Erk5HUQ,
  _0_siteConfig_NVZh1T3TWz,
  payload_client_lRIV5jvWvn,
  navigation_repaint_client_axGeyExSd1,
  check_outdated_build_client_g6OIFAGfE9,
  chunk_reload_client_GTpBvMlv7H,
  plugin_vue3_fCebrjpjh2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TXWEeGhtXx,
  plugin_eZujz8SZgH,
  plugin_xuilZ67r4n,
  plugin_xhQucnHIpZ,
  gsapPlugin_HlwIIyl85L,
  plugin_AY2LKbRPV8,
  plugin_wy0B721ODc,
  mitterPlugin_7o1kq7Af92,
  anchorscroll_plugin_tURrw4tB2p,
  _001_notivue_client_bE2FHKqHiu,
  motion_MbmVjsAnhb,
  plugin_client_O82MEhe1N4,
  auth_redirect_oZ7X7bJSWr,
  cookiebot_plugin_ZvcVAUHuwL,
  siteConfig_G9eIb33fMz,
  inferSeoMetaPlugin_Iry4UXOpFX,
  titles_ky8Wa2oS6E,
  plugin_tWq9saXzrj,
  _0_22_drag_select_midNNg2mzb,
  _0_24_Vue3Lottie_client_nF1VBylm6x,
  _0_94_plyr_client_b751RLv0Mr,
  _0_95_scattergl_client_YbTedD7arR,
  _0_95_vercel_analytics_client_AHihIkYZm2,
  _010_sentry_client_qtk3FqHvvX,
  _010_vue_code_layout_client_tAsBXuI5pR,
  _012_vue_photo_preview_client_xt8Txlkm6Q,
  _020_ag_grid_client_CBw0bhLalg,
  _020_fit2box_client_7dQ8xfNgoy,
  _021_vueuse_gesture_5lK55OQwFQ,
  _023_json_viewer_client_CwNwUoGvLp,
  _030_uplot_client_sOHiDendNY,
  _040_auto_animate_client_kSN6cGeXRu,
  _070_uppy_client_yepT13vxoE,
  _080_error_handler_pzY4GmDTGp,
  _091_vgrid_client_lex8Ugz66y,
  _093_useLicense_client_ZxTFj52SQu,
  authNavGuard_client_5VQHzXtSXe,
  vue_code_layout_client_PIvC8TjfHX,
  defaults_GshYTuZiKk
]