import { onMounted, onUnmounted } from 'vue'
import { useEventListener } from '@vueuse/core'

export const useDisableZoom = () => {
  const handleTouchMove = (e: TouchEvent) => {
    // Prevent zooming if there are two or more touches (pinch gesture)
    if (e.touches.length > 1) {
      e.preventDefault()
    }
  }

  const handleWheel = (e: WheelEvent) => {
    // Prevent zooming with Ctrl + wheel
    if (e.ctrlKey) {
      e.preventDefault()
    }
  }

  onMounted(() => {
    // Add passive: false to allow preventDefault()
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('wheel', handleWheel, { passive: false })

    // Double-tap prevention
    useEventListener(document, 'gesturestart', (e) => e.preventDefault())
    useEventListener(document, 'gesturechange', (e) => e.preventDefault())
    useEventListener(document, 'gestureend', (e) => e.preventDefault())
  })

  onUnmounted(() => {
    document.removeEventListener('touchmove', handleTouchMove)
    document.removeEventListener('wheel', handleWheel)
  })
} 