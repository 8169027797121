import { defineNuxtPlugin } from '#app'
import VueCodeLayout from '@edanweis/vue-code-layout'

// Add TypeScript declaration for the global flag
declare global {
  interface Window {
    VUE_CODE_LAYOUT_READY?: boolean;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  try {
    nuxtApp.vueApp.use(VueCodeLayout)
    
    // Set a global flag to indicate the plugin is ready
    if (import.meta.client) {
      console.log('[vue-code-layout] Plugin initialization started')
      
      // Add a small delay to ensure the plugin is fully initialized
      setTimeout(() => {
        console.log('[vue-code-layout] Plugin initialized successfully')
        window.VUE_CODE_LAYOUT_READY = true
        
        // Dispatch a custom event that components can listen for
        window.dispatchEvent(new CustomEvent('vue-code-layout-ready'))
      }, 500)
    }
    
    return {
      provide: {
        vueCodeLayout: true
      }
    }
  } catch (error) {
    console.error('[vue-code-layout] Failed to initialize plugin:', error)
    return {
      provide: {
        vueCodeLayout: false
      }
    }
  }
}) 