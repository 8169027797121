import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/vercel/path0/layouts/account.vue").then(m => m.default || m)),
  auth: defineAsyncComponent(() => import("/vercel/path0/layouts/auth.vue").then(m => m.default || m)),
  captions: defineAsyncComponent(() => import("/vercel/path0/layouts/captions.vue").then(m => m.default || m)),
  dashboard: defineAsyncComponent(() => import("/vercel/path0/layouts/dashboard.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/vercel/path0/layouts/default.vue").then(m => m.default || m)),
  invite: defineAsyncComponent(() => import("/vercel/path0/layouts/invite.vue").then(m => m.default || m)),
  nothing: defineAsyncComponent(() => import("/vercel/path0/layouts/nothing.vue").then(m => m.default || m)),
  project: defineAsyncComponent(() => import("/vercel/path0/layouts/project.vue").then(m => m.default || m))
}