import { defineStore } from 'pinia'
import type { RealtimeChannel } from '@supabase/supabase-js'

interface ProjectTimeline {
  phases: any[]
  start_date: string | null
  end_date: string | null
  milestones: any[]
}

interface Project {
  id: number
  title: string
  owner_id: string
  owner_type: string
  created_at: string
  updated_at: string
  project_location?: any // geometry type
  project_timeline?: ProjectTimeline
  project_scale?: number | null
  design_objectives?: string[]
  budget_level?: number | null
  additional_preferences?: Record<string, any>
  formatted_address?: string | null
  address_components?: Record<string, any> | null
}

export const useProjectStore = defineStore('ProjectStore', () => {
    const owner_type = ref<'team' | 'group' | 'user'>('group')
    const projects = ref<Project[]>([])
    const selectedProject = ref<Project | null>(null)
    const syncStatus = ref('saved')
    let realtimeChannel: RealtimeChannel | null = null
    
    const setSyncStatus = (status: string) => {
        syncStatus.value = status
    }

    const setProjects = (data: Project[]) => {
        projects.value = data
    }

    const setSelectedProject = (data: Project) => {
        selectedProject.value = data
    }

    const setOwnerType = (type: 'team' | 'group' | 'user') => {
        owner_type.value = type
    }

    const updateProject = (id: number, data: Partial<Project>) => {
        const index = projects.value.findIndex(p => p.id === id)
        if (index !== -1) {
            projects.value[index] = { ...projects.value[index], ...data }
            if (selectedProject.value?.id === id) {
                selectedProject.value = { ...selectedProject.value, ...data }
            }
        }
    }

    const addToProjects = (data: Project) => {
        if (!projects.value.find(p => p.id === data.id)) {
            projects.value.push(data)
        }
    }

    const removeFromProjects = (id: number) => {
        const index = projects.value.findIndex(p => p.id === id)
        if (index !== -1) {
            projects.value.splice(index, 1)
            if (selectedProject.value?.id === id) {
                selectedProject.value = null
            }
        }
    }

    // Handle realtime updates
    const handleRealtimeChanges = (payload: any) => {
        const { eventType, new: newRecord, old: oldRecord } = payload
        
        switch (eventType) {
            case 'INSERT':
                addToProjects(newRecord)
                break
            case 'UPDATE':
                updateProject(newRecord.id, newRecord)
                break
            case 'DELETE':
                removeFromProjects(oldRecord.id)
                break
        }
    }

    // Subscribe to realtime updates
    const subscribeToProjects = () => {
        const client = useSupabaseClient()
        
        realtimeChannel = client.channel('public:projects')
            .on(
                'postgres_changes',
                { 
                    event: '*', 
                    schema: 'public', 
                    table: 'projects',
                    filter: `id=eq.${selectedProject.value?.id}` 
                },
                async (payload) => {
                    // Fetch complete project data including formatted_address
                    const { data: fullProject } = await client
                        .from('projects')
                        .select(`
                            id,
                            title,
                            owner_id,
                            owner_type,
                            created_at,
                            updated_at,
                            project_location,
                            project_timeline,
                            project_scale,
                            design_objectives,
                            budget_level,
                            additional_preferences,
                            formatted_address
                        `)
                        .eq('id', payload.new.id)
                        .single()

                    if (fullProject) {
                        handleRealtimeChanges({
                            ...payload,
                            new: fullProject
                        })
                    }
                }
            )
            .subscribe()
    }

    // Cleanup function
    const unsubscribeFromProjects = () => {
        if (realtimeChannel) {
            const client = useSupabaseClient()
            client.removeChannel(realtimeChannel)
            realtimeChannel = null
        }
    }
    
    return {
        projects,
        setProjects,
        addToProjects,
        selectedProject,
        setSelectedProject,
        updateProject,
        syncStatus,
        setSyncStatus,
        subscribeToProjects,
        unsubscribeFromProjects,
        removeFromProjects,
        owner_type
    }
}, {
    persist: false
})
