export const menuItems = [
  {
    item: {
      icon: "streamline:nature-ecology-rice-field-sun-rise-set-field-crop-produce-farm",
      title: "Wellspring",
      href: "/wellspring",
      titleClass: "text-grower",
      image: {
        src: "https://ik.imagekit.io/8qxqs9cznn/general/SCR-20250220-iysl.png",
        title: "Wellspring",
        description: "Join the global marketplace where nature professionals pay for value-proven expertise.",
      }
    },
    subMenuItems: [
      {
        title: "Wellspring",
        href: "/wellspring",
        description: "Join the global marketplace where nature professionals pay for value-proven expertise",
      },
      
    ],
  },
  {
    item: {
      icon: "ant-design:aliwangwang-outlined",
      title: "Workspace",
      href: "/design",
      titleClass: "text-designer",  
      image: {
        src: "https://ik.imagekit.io/8qxqs9cznn/general/4640991169_0a27fd502e_k.jpg",
        title: "SuperSeeded Workspace",
        description: "The future of planting and procurement for landscape architects, contractors and growers..",
      }
    },
    
    subMenuItems: [
      {
        title: "The future of planting and procurement for landscape architects, contractors and growers..",
        href: "/planting",
        description: `10x faster planting design and procurement. Real-time availability, pricing and best practice advice`
      },
    ],
  }
];