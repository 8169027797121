import { createHash } from 'crypto'
import hashSum from 'hash-sum';
export const useProjectData = () => {



  const projectMenu = [
    { 
    type: 'item', 
    label: 'Delete project', 
    shortcut: '',
    action: (project_id: string) => { 
      deleteProject(project_id)
    }
  },
  ]

  const deleteProject = (project_id: string) => {
    const client = useSupabaseClient()
    return client.from('projects').delete().eq('id', project_id)
  }
  
  const getProjectsByOwnerId = (id: string) => {
    const client = useSupabaseClient();
    const { data, refresh, pending } = useLazyAsyncData(
      `projects-${id}`,
      async () => {
        const { data } = await client
          .from("projects")
          .select("id,title,owner_id,owner_type,created_at,updated_at")
          .eq("owner_id", id);
        return data;
      },
      {
        dedupe: "defer", // Avoid making new requests if there is a pending request
        immediate: true, // Set to false if you want to control when the request is made
        getCachedData: (key) => {
          // Implement your cache retrieval logic here
          return useNuxtApp().payload.data[key] || null;
        },
      }
    );
    return {data, refresh, pending}
  }; 
  
  const getProjectsByParticipantId = (id: string) => {
    const client = useSupabaseClient();
    const { data, refresh, pending } = useLazyAsyncData(
      `projects-participant-${id}`,
      async () => {
        const { data } = await client
          .from("project_participants")
          .select("*")
          .eq("user_id", id);
        return data;
      },
      {
        dedupe: "defer", // Avoid making new requests if there is a pending request
        immediate: true, // Set to false if you want to control when the request is made
        getCachedData: (key) => {
          // Implement your cache retrieval logic here
          return useNuxtApp().payload.data[key] || null;
        },
      }
    );
    return {data, refresh, pending}
  };

  const getAllUserProjects = (
    userId: string,
    recentlyModified: boolean = false,
    limit: number = 10
  ) => {
    const client = useSupabaseClient();
    const { data, refresh, status } = useAsyncData(
      `all-user-projects-${userId}-${recentlyModified}-${limit}`,
      async () => {
        try {
          const fiveDaysAgo = new Date();
          fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

          console.log(
            `Fetching projects for userId: ${userId}, recentlyModified: ${recentlyModified}, limit: ${limit}`
          );

          // Fetch owned projects
          let ownedProjectsQuery = client
            .from("projects")
            .select(`
              id,
              title,
              owner_id,
              owner_type,
              created_at,
              updated_at,
              project_location,
              formatted_address,
              project_timeline,
              project_scale,
              design_objectives,
              budget_level,
              additional_preferences
            `)
            .eq("owner_id", userId)
            .eq("owner_type", "user");

          if (recentlyModified) {
            ownedProjectsQuery = ownedProjectsQuery.gte(
              "updated_at",
              fiveDaysAgo.toISOString()
            );
          }

          const { data: ownedProjects, error: ownedError } =
            await ownedProjectsQuery;

          if (ownedError) {
            console.error("Error fetching owned projects:", ownedError);
            return [];
          }

          console.log(`Owned Projects Retrieved: ${ownedProjects.length}`);

          // Fetch projects where user is participant
          const { data: participantProjects, error: participantError } =
            await client
              .from("project_participants")
              .select("project_id")
              .eq("user_id", userId);

          if (participantError) {
            console.error(
              "Error fetching participant projects:",
              participantError
            );
            return ownedProjects || [];
          }

          if (!participantProjects || participantProjects.length === 0) {
            console.log("No participant projects found for user.");
            return ownedProjects || [];
          }

          const participantProjectIds = participantProjects.map(
            (p) => p.project_id
          );
          console.log("Participant Project IDs:", participantProjectIds);

          // Fetch full details of participant projects
          let participantProjectsQuery = client
            .from("projects")
            .select(`
              id,
              title,
              owner_id,
              owner_type,
              created_at,
              updated_at,
              project_location,
              formatted_address,
              project_timeline,
              project_scale,
              design_objectives,
              budget_level,
              additional_preferences
            `)
            .in("id", participantProjectIds);

          if (recentlyModified) {
            participantProjectsQuery = participantProjectsQuery.gte(
              "updated_at",
              fiveDaysAgo.toISOString()
            );
          }

          const { data: fullParticipantProjects, error: fullParticipantError } =
            await participantProjectsQuery;

          if (fullParticipantError) {
            console.error(
              "Error fetching full participant projects:",
              fullParticipantError
            );
            return ownedProjects || [];
          }

          console.log(
            `Participant Projects Retrieved: ${fullParticipantProjects.length}`
          );

          // Combine and deduplicate projects
          const allProjects = [
            ...(ownedProjects || []),
            ...(fullParticipantProjects || []),
          ];
          const uniqueProjects = allProjects.filter(
            (project, index, self) =>
              index === self.findIndex((t) => t.id === project.id)
          );

          console.log(
            `Total Unique Projects after Deduplication: ${uniqueProjects.length}`
          );

          // Fetch team information for projects owned by groups
          const groupOwnedProjects = uniqueProjects.filter(p => p.owner_type === 'group');
          const groupIds = [...new Set(groupOwnedProjects.map(p => p.owner_id))];

          if (groupIds.length > 0) {
            const { data: teams, error: teamsError } = await client
              .from("groups")
              .select("*")
              .in("id", groupIds);

            if (teamsError) {
              console.error("Error fetching teams:", teamsError);
            } else {
              // Add team information to projects
              uniqueProjects.forEach(project => {
                if (project.owner_type === 'group') {
                  project.team = teams.find(t => t.id === project.owner_id);
                }
              });
            }
          }

          // Sort the uniqueProjects array by updated_at in descending order
          const sortedProjects = uniqueProjects.sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          );

          console.log("Projects Sorted by `updated_at`");

          // Apply the limit
          const limitedProjects = sortedProjects.slice(0, limit);
          console.log(
            `Returning ${limitedProjects.length} projects after applying limit.`
          );

          return limitedProjects;
        } catch (error) {
          console.error("Unexpected error in `getAllUserProjects`:", error);
          return null;
        }
      },
      {
        dedupe: "defer",
        immediate: true,
        server: false, // Don't run on server
        lazy: true, // Don't show loading bar
        watch: false, // Don't watch for changes
        getCachedData: (key) => {
          return useNuxtApp().payload.data[key] || null;
        },
      }
    );

    return { data, refresh, status };
  };
  const getPanelProps = (
    project_id: string,
    owner_id: string,
    owner_type: string,
    useFetch: boolean = false
  ) => {
    const client = useSupabaseClient();

    const fetchFunction = async () => {
      const { data, error } = await client
        .from("projectpanels")
        .select("*")
        .eq("project_id", project_id)
        .eq(owner_type === "user" ? "user_id" : "group", owner_id);

      if (error) {
        console.error("Error fetching panel props:", error);
        throw error;
      }

      console.log("got panel props", data);
      return data;
    };

    if (useFetch) {
      return fetchFunction()
        .then((data) => ({ data, status: "success", error: null }))
        .catch((err) => {
          console.error("Unexpected error:", err);
          return { status: "error", error: err };
        });
    } else {
      const { data, refresh, status, error } = useLazyAsyncData(
        `projectpanels-${owner_id}-${project_id}`,
        fetchFunction,
        {
          dedupe: "defer",
          immediate: true,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
        }
      );
      return { data, refresh, status, error };
    }
  };

  const getPanelAnalysis = (
    panel_id: string,
    useFetch: boolean = false
  ) => {
    const client = useSupabaseClient();

    const fetchFunction = async () => {
      const { data, error } = await client
        .from("projectdata")
        .select("*")
        .eq("panel_id", panel_id)

      if (error) {
        console.error("Error fetching panel analysis:", error);
        throw error;
      }
      return data;
    };

    if (useFetch) {
      return fetchFunction()
        .then((data) => ({ data, status: "success", error: null }))
        .catch((err) => {
          console.error("Unexpected error:", err);
          return { status: "error", error: err };
        });
    } else {
      const { data, refresh, status, error } = useLazyAsyncData(
        `panelanalysis-${panel_id}`,
        fetchFunction,
        {
          dedupe: "defer",
          immediate: true,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
        }
      );
      return { data, refresh, status, error };
    }
  };    
  
  const getProjectMembersByProjectId = async (id: string) => {
    const client = useSupabaseClient()
    const { data, refresh, pending } = useLazyAsyncData(
      `project-members-${id}`,
      async () => {
        const { data, error } = await client.from('project_participants').select('*').eq('project_id', id)
        return data
        // const {data: projectMembers} = await client.from('group_users_with_details').select('*').eq('group_id', data[0].group_id)
        // return projectMembers
      }
    )
    return {data, refresh, pending}
  }

  const getTeamMembersByGroupId = async (id: string, selectFields: string = '*') => {
    // query a view to enable a join group_users and users and include avatar_url
    const client = useSupabaseClient()
    const { data, refresh, pending } = useLazyAsyncData(
      `team-members-${id}`,
      async () => {
        const {data, error} = await client.from('group_users_with_details').select(selectFields).eq('group_id', id)
        return data
      }
    )
    return {data, refresh, pending}
  }

  async function checkUserFileAccess(id: string, fileId: string) {
    const client = useSupabaseClient()
    
    const { data, error } = await client
      .from("user_files")
      .select("file_id")
      .eq("user_id", id)
      .eq("file_id", fileId);

    if (error) {
      console.error("Error checking file access:", error);
      return false;
    }

    return data.length > 0;
  }

async function createNewProject(project: any) {
  const client = useSupabaseClient();
  const user = useSupabaseUser()

  if (!project.title || !project.owner_id || !project.owner_type) {
    console.error("Missing required project fields", project);
    return null;
  }

  try {
    // Create the project
    const { data: projectData, error: projectError } = await client
      .from("projects")
      .insert(project)
      .select("id,title,owner_id,owner_type,created_at,updated_at,project_location,project_timeline,project_scale,design_objectives,budget_level,additional_preferences,formatted_address")
      .single();

    if (projectError) {
      console.error("Error inserting into project table:", projectError);
      return null;
    }

    // Add the owner as a participant
    const { error: participantError } = await client
      .from("project_participants")
      .insert({ project_id: projectData.id, user_id: user.value.id });

    if (participantError) {
      console.error("Error adding owner as participant:", participantError);
      // Consider whether to delete the project if this fails
      // or implement a cleanup/retry mechanism
    }

    console.log("Project created successfully:", projectData);
    return projectData;
  } catch (error) {
    console.error("Unexpected error creating project:", error);
    return null;
  }
}
async function saveFileMetadata(userId: string, fileId: string, metadata: any) {
  const client = useSupabaseClient();

  try {
    const { data, error } = await client
      .from("user_files")
      .upsert({ user_id: userId, file_id: fileId, metadata }, { onConflict: 'user_id,file_id' })
      .select()
      .single();

    if (error) {
      console.error("Error upserting file metadata:", error);
      return null;
    }

    console.log("File metadata saved successfully:", data);
    return data;
  } catch (error) {
    console.error("Unexpected error saving file metadata:", error);
    return null;
  }
}

  async function upsertProjectData(
    panel_id: string,
    tool_uid: string,
    fileanalysis_id: string | null,
    projectanalysis_id: string | null,
    project_id: string
  ) {
    const client = useSupabaseClient();
    try {
      return await client
        .from("projectdata")
        .upsert(
          {
            panel_id,
            fileanalysis_id,
            projectanalysis_id,
            project_id,
            tool_uid
          },
          { onConflict: "panel_id,fileanalysis_id" }
        )
        .select();
    } catch (error) {
      console.warn("Error upserting project data:", error);
      return error;
    }
  }

  async function upsertProjectAnalysis(project_id: string, analysis: any) {
    const client = useSupabaseClient()
    try {
      return await client
        .from("projectanalysis")
        .upsert(
          {
            output: analysis.output,
            project_id,
            modified_at: analysis.created_at,
            tool_uid: analysis.tool_uid
          },
          { onConflict: "project_id,tool_uid" }
        )
        .select();
    } catch (error) {
      console.error("Error updating project analysis:", error);
      return error
    }
  }
    
  const getProjectAnalysis = (
    projectId: string,
    tool_uid: string,
    beforeId?: string,
    useFetch: boolean = false
  ) => {
    const client = useSupabaseClient();

    const fetchFunction = async () => {
      let query = client
        .from("projectanalysis")
        .select("*")
        .eq("project_id", projectId)
        .eq("tool_uid", tool_uid)
        .order("modified_at", { ascending: false })
        .limit(1);

      if (beforeId) {
        const { data: beforeAnalysis } = await client
          .from("projectanalysis")
          .select("modified_at")
          .eq("id", beforeId)
          .single();

        if (beforeAnalysis) {
          query = query.lt("modified_at", beforeAnalysis.modified_at);
        }
      }

      const { data, error } = await query.single();
      console.log("got project analysis", data);

      if (error) {
        console.error("Error fetching project analysis:", error);
        throw error;
      }

      return data;
    };

    if (useFetch) {
      return fetchFunction()
        .then((data) => ({ data, status: "success", error: null }))
        .catch((err) => {
          console.error("Unexpected error:", err);
          return { status: "error", error: err };
        });
    } else {
      const { data, refresh, status } = useLazyAsyncData(
        `project-analysis-${projectId}-${tool_uid}-${beforeId || "latest"}`,
        fetchFunction,
        {
          dedupe: "defer",
          immediate: true,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
        }
      );
      return { data, refresh, status };
    }
  };
  
  const getProjectPanelsWithFileAndProjectAnalysis = (
    projectId: string,
    panelId?: string
  ) => {
    const client = useSupabaseClient();
    const { data, refresh, status } = useLazyAsyncData(
      `project-panels-analysis-${projectId}-${panelId || "all"}`,
      async () => {
        // Step 1: Fetch project panels with joins
        let panelsQuery = client
          .from("projectpanels")
          .select(
            `*,
          projectdata (
            *,
            projectanalysis:projectanalysis_id (*),
            fileanalysis:fileanalysis_id (*)
          )
        `
          )
          .eq("project_id", projectId);

        if (panelId) {
          panelsQuery = panelsQuery.eq("panel_id", panelId);
        }

        const { data: panels, error: panelsError } = await panelsQuery;
        console.log('panels', panels)
        if (panelsError) {
          console.error("Error fetching project panels:", panelsError);
          return null;
        }

        // Process the data to combine panels with analysis and file analysis
        return panels.reduce((acc, panel) => {
          const projectData = panel.projectdata || [];
          const analysis = projectData
            .map((pd) => pd.projectanalysis)
            .filter(Boolean);
          const fileAnalysisData = projectData
            .map((pd) => pd.fileanalysis)
            .filter(Boolean);

          acc[panel.panel_id] = {
            ...panel,
            props: {
              ...panel.props,
              data: {
                projectanalysis: analysis,
                fileanalysis: fileAnalysisData,
              },
            },
          };

          return acc;
        }, {});
      },
      {
        dedupe: "defer",
        immediate: true,
        getCachedData: (key) => {
          return useNuxtApp().payload.data[key] || null;
        },
      }
    );

    return { data, refresh, status };
  };

  const upsertFileAnalysis = async (
    output: any,
    file_id: string,
    created_at: Date,
    finished_at: Date,
    running: boolean,
    tool_uid: string
  ) => {
    const client = useSupabaseClient();
    console.log('upsertFileAnalysis', {output, file_id, created_at, finished_at, running, tool_uid})
    try {
      // Check if a file analysis already exists for this file_id and tool
      const { data: existingFileAnalysis, error: fetchError } = await client
        .from("fileanalysis")
        .select("*")
        .eq("file_id", file_id)
        .eq("tool_uid", tool_uid)
        .limit(1)
        .single();

      if (fetchError && fetchError.code !== "PGRST116") {
        console.warn("fetching existing file analysis", fetchError);
      }

      if (existingFileAnalysis) {
        // Update existing file analysis
        return await client
          .from("fileanalysis")
          .update({ output, created_at, finished_at, running, tool_uid })
          .eq("id", existingFileAnalysis.id)
          .select()
          .single();
        
      } else {
        // Insert new file analysis
        return await client
          .from("fileanalysis")
          .insert({
            output,
            file_id,
            created_at,
            finished_at,
            running,
            tool_uid,
          })
          .select()
          .single();
      }

    } catch (error) {
      console.error("Error upserting file analysis:", error);
      throw error;
    }
  };

  const getProjectPanelsWithAnalysis = (
    projectId: string,
    panelId?: string,
    tool_uid?: string,
    useFetch: boolean = false,
    enableLogging: boolean = true
  ) => {
    const client = useSupabaseClient();

    const log = (message: string, data?: any) => {
      if (enableLogging) {
        if (data !== undefined) {
          console.log(`[getProjectPanelsWithAnalysis] ${message}`, data);
        } else {
          console.log(`[getProjectPanelsWithAnalysis] ${message}`);
        }
      }
    };

    const fetchFunction = async () => {
      log('Starting fetchFunction with args:', {projectId, panelId, tool_uid, useFetch, enableLogging});

      // Step 1: Fetch project panels
      let panelsQuery = client
        .from('projectpanels')
        .select('*')
        .eq('project_id', projectId);

      // if (panelId) {
      //   panelsQuery = panelsQuery.eq('panel_id', panelId);
      // } 
      
      log('Fetching project panels');
      const { data: panels, error: panelsError } = await panelsQuery;
      log('panelsQuery', panels);

      if (panelsError) {
        log('Error fetching project panels:', panelsError);
        return null;
      }
      log('Fetched project panels:', panels);

      // Step 2: Fetch project analysis
      log('Fetching project analysis with:', {tool_uid, projectId});
      const { data: projectAnalysis, error: analysisError } = await client
        .from('projectanalysis')
        .select('*')
        .eq('project_id', String(projectId))
        .eq('tool_uid', String(tool_uid))
        .single();

      if (analysisError) {
        log('Error fetching project analysis:', analysisError);
        return null;
      }
      log('Fetched project analysis:', projectAnalysis);

      await upsertProjectAnalysis(projectId, projectAnalysis);

      // Process the data to combine panels with analysis and file analysis
      log('Processing data');
      const result = panels.reduce((acc, panel) => {
        const analysis = projectAnalysis.find(a => a.project_id === panel.project_id);
        const fileAnalysisData = fileAnalysis.find(fa => fa.id === panel.props?.fileanalysis_id);

        acc[panel.panel_id] = {
          ...panel,
          props: {
            ...panel.props,
            data: {
              projectanalysis: analysis,
            }
          }
        };

        return acc;
      }, {});

      log('Processed result:', result);
      return result;
    };

    if (useFetch) {
      log('Using fetch');
      return fetchFunction()
        .then((data) => {
          log('Fetch successful');
          return { data, status: "success", error: null };
        })
        .catch((err) => {
          log('Fetch error:', err);
          return { status: "error", error: err };
        });
    } else {
      log('Using useLazyAsyncData');
      const { data, refresh, status } = useLazyAsyncData(
        `project-panels-analysis-${projectId}-${panelId || 'all'}`,
        fetchFunction,
        {
          dedupe: "defer",
          immediate: true,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
        }
      );
      log('useLazyAsyncData result:', { data, status });
      return { data, refresh, status };
    }
  };

  const getProjectPanelsWithAnalyses = (
    panelId?: string,
    tool_uid?: string,
    project_id?: string,
    useFetch: boolean = false,
    enableLogging: boolean = true
  ) => {
    const client = useSupabaseClient();

    const log = (message: string, data?: any) => {
      if (enableLogging) {
        if (data !== undefined) {
          console.log(`[getProjectPanelsWithAnalysis] ${message}`, data);
        } else {
          console.log(`[getProjectPanelsWithAnalysis] ${message}`);
        }
      }
    };

    const fetchFunction = async () => {
      log('Starting fetchFunction with args:', {project_id, panelId, tool_uid, useFetch, enableLogging});

      let panelsQuery = client
        .from("projectdata")
        .select(
          `fileanalysis (*),
          projectanalysis (*)`
              // projectpanels (*) // TODO: do we need this?
        )
        .eq("project_id", project_id)
        .eq("tool_uid", tool_uid)
      
      log('Fetching project panels');
      const { data: panelsData, error: panelsError } = await panelsQuery;
      log('panelsQuery', panelsData);

      if (panelsError) {
        log('Error fetching project panels:', panelsError);
        return null;
      }
      log('Fetched project panels:', panelsData);

      // Process the data to remove duplicates
      const uniqueData = panelsData.reduce((acc, item) => {
        const key = `${item.fileanalysis_id}-${item.project_id}-${item.projectanalysis_id}`;
        if (!acc[key]) {
          acc[key] = item;
        }
        return acc;
      }, {});

      // Convert the object back to an array
      const result = Object.values(uniqueData);

      log('Processed result:', result);
      return result;
    };

    if (useFetch) {
      log('Using fetch');
      return fetchFunction()
        .then((data) => {
          log('Fetch successful');
          return { data, status: "success", error: null };
        })
        .catch((err) => {
          log('Fetch error:', err);
          return { status: "error", error: err };
        });
    } else {
      log('Using useLazyAsyncData');
      const { data, refresh, status } = useLazyAsyncData(
        `project-panels-analyses-${project_id}-${panelId}`,
        fetchFunction,
        {
          dedupe: "defer",
          immediate: true,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
        }
      );
      log('useLazyAsyncData result:', { data, status });
      return { data, refresh, status };
    }
  };


  const addThumbnailToLayout = async (versionId: string, thumbnailId: string) => {
    const client = useSupabaseClient();
    const { data, error } = await client
      .from('layout_state_versions')
      .update({ thumbnail_id: thumbnailId })
      .eq('id', versionId)
      .select()
      .single();
    if (error) throw error;
    return data;
  }

  // const addThumbnailToLayout = async (layoutId: string, thumbnailId: string) => {
  //   const client = useSupabaseClient();
  //   const { data, error } = await client
  //     .from('projectlayouts')
  //     .update({ thumbnail_id: thumbnailId })
  //     .eq('id', layoutId)
  //     .select()
  //     .single();
  //   if (error) throw error;
  //   return data;
  // }

  

  const saveProjectLayout = async (projectId: number, ownerId: string, ownerType: 'user' | 'group', layoutData: any, addNew: boolean = false) => {
    const client = useSupabaseClient();

    try {
      // Generate a unique state_id for this project/owner combination
      const stateId = `${projectId}-${ownerId}`;

      // First, check if a layout state already exists for this project and owner
      const { data: existingLayout, error: fetchError } = await client
        .from('layout_states')
        .select('*')
        .eq('state_id', stateId)
        .eq('user_id', ownerId)
        .limit(1)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        // PGRST116 means no rows returned, which is fine for a new layout
        console.error('Error fetching existing layout:', fetchError);
        throw fetchError;
      }

      let result;

      if (existingLayout && !addNew) {
        // Update existing layout state
        const { data, error } = await client
          .from('layout_states')
          .update({ layout: layoutData })
          .eq('id', existingLayout.id)
          .select()
          .single();

        if (error) throw error;
        result = data;

        // Create a new version
        await client
          .from('layout_state_versions')
          .insert({
            state_id: existingLayout.id,
            version_name: new Date().toISOString(),
            layout: layoutData
          });
      } else {
        // Insert new layout state
        const { data, error } = await client
          .from('layout_states')
          .insert({
            state_id: stateId,
            user_id: ownerId,
            layout: layoutData
          })
          .select()
          .single();

        if (error) throw error;
        result = data;

        // Create initial version
        await client
          .from('layout_state_versions')
          .insert({
            state_id: result.id,
            version_name: 'initial',
            layout: layoutData
          });
      }

      return result;
    } catch (error) {
      push.error({ title: "Project Session", message: `Error saving project session: ${error}` });
      throw error;
    }
  };

  const deleteProjectPanel = async (panelId: string) => {
    const client = useSupabaseClient();

    try {
      const { data, error } = await client
        .from("projectpanels")
        .delete()
        .eq("panel_id", panelId);

      if (error) {
        console.error("Error deleting project panel:", error);
        throw error;
      }

      return data;
    } catch (error) {
      push.error({
        title: "Project Panel",
        message: `Error deleting project panel: ${error}`,
      });
      throw error;
    }
  };


  const saveProjectPanel = async (
    panelId: string,
    panelProps: any,
    projectId: number,
    ownerId: string,
    ownerType: "user" | "group"
  ) => {
    const client = useSupabaseClient();

    try {
      // Ensure projectId is not null
      if (!projectId) {
        throw new Error("Project ID is required");
      }

      // First, check if a panel already exists for this project and owner
      const { data: existingPanel, error: fetchError }   = await client
        .from("projectpanels")
        .select("*")
        .eq("panel_id", panelId)
        .eq("project_id", projectId)
        .eq(ownerType === "user" ? "user_id" : "group", ownerId)
        .single();

      if (fetchError && fetchError.code !== "PGRST116") {
        console.warn('fetching existing panel', fetchError);
      }

      let result;

      if (existingPanel) {
        // Update existing panel
        const { data, error } = await client
          .from("projectpanels")
          .update({ props: panelProps })
          .eq("id", existingPanel.id)
          .select()
          .single();

        if (error) throw error;
        result = data;
      } else {
        // Insert new panel
        const { data, error } = await client
          .from("projectpanels")
          .insert({
            panel_id: panelId,
            props: panelProps,
            project_id: projectId,
            [ownerType === "user" ? "user_id" : "group"]: ownerId,
          })
          .select()
          .single();

        if (error) throw error;
        result = data;
      }

      return result;
    } catch (error) {
      console.error("Error saving project panel:", error);
      throw error;
    }
  };
  

  const getProjectLayouts = (
    projectId: string,
    ownerId?: string,
    ownerType: "user" | "group" = "user",
    pickBy?: string,
    useFetch: boolean = false
  ) => {
    const client = useSupabaseClient();

    // Validate projectId
    if (!projectId || projectId === 'undefined') {
      console.warn('Invalid project ID provided to getProjectLayouts:', projectId);
      return { data: [], status: "error", error: new Error('Invalid project ID') };
    }

    const fetchFunction = async () => {
      // Generate the state_id pattern for this project
      const stateIdPattern = `${projectId}-%`;

      let query = client
        .from("layout_states")
        .select(`
          id,
          state_id,
          user_id,
          layout,
          created_at,
          updated_at,
          layout_state_versions (
            id,
            version_name,
            layout,
            created_at
          )
        `)
        .like('state_id', stateIdPattern);

      // Filter by user_id if provided
      if (ownerId) {
        query = query.eq('user_id', ownerId);
      }

      // Add sorting if pickBy is defined and not empty
      if (pickBy && pickBy.trim() !== '') {
        query = query.order(pickBy === 'last_modified' ? 'updated_at' : pickBy, { ascending: false });
      }

      const { data, error } = await query;

      if (error) {
        console.error("Error fetching project layouts:", error);
        throw error;
      }

      // Transform the data to match the old format
      const transformedData = data?.map(state => ({
        id: state.id,
        project_id: parseInt(state.state_id.split('-')[0]),
        user_id: state.user_id,
        data: state.layout,
        last_modified: state.updated_at,
        versions: state.layout_state_versions
      })) || [];

      return transformedData;
    };

    if (useFetch) {
      return fetchFunction()
        .then((data) => ({ data, status: "success", error: null }))
        .catch((err) => {
          console.error("Unexpected error:", err);
          return { status: "error", error: err, data: [] };
        });
    } else {
      const { data, refresh, status } = useAsyncData(
        `project-layouts-${projectId}-${ownerId || "all"}`,
        fetchFunction
      );
      return { data, refresh, status };
    }
  };

  const getPanelFile = (panel_id: string, useFetch: boolean = false) => {
    const client = useSupabaseClient();

    const fetchFunction = async () => {
      const { data, error } = await client
        .from("projectdata")
        .select(`
          fileanalysis_id,
          panel_id,
          fileanalysis:fileanalysis_id (
            file_id,
            file:file_id (
              filename,
              s3_key,
              url
            )
          )
        `)
        .eq("panel_id", panel_id)
        .single();

      if (error) {
        console.error("Error fetching panel file:", error);
        throw error;
      }

      console.log("got panel file", data);
      return data;
    };

    if (useFetch) {
      return fetchFunction()
        .then((data) => ({ data, status: "success", error: null }))
        .catch((err) => {
          console.error("Unexpected error:", err);
          return { status: "error", error: err };
        });
    } else {
      const { data, refresh, status, error } = useLazyAsyncData(
        `panel-file-${panel_id}`,
        fetchFunction,
        {
          dedupe: "defer",
          immediate: true,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
        }
      );
      return { data, refresh, status, error };
    }
  }; 

const savePanelStates = async (panelStatesToSave: any[]) => {
  const client = useSupabaseClient();
  const panelStatesWithMetadata = panelStatesToSave.map(panelState => ({
    ...panelState,
    // metadata: panelState.data?.metadata || panelState.output?.metadata
  }));
  try {
    console.log('Saving panel states:', panelStatesToSave);
    const { data, error } = await client
      .from("panelstates")
      .upsert(panelStatesWithMetadata, {
        onConflict: 'panel_id,project_id,tool_uid',
        ignoreDuplicates: false
      })
      .select();

    if (error) {
      console.error('Error saving panel states:', error);
      return { error, data: null };
    }

    console.log('Panel states saved successfully:', data);
    return { error: null, data };
  } catch (error) {
    console.error('Unexpected error saving panel states:', error);
    return { error, data: null };
  }
};

const updatePanelStateOutput = async (
  panelId: string,
  projectId: string,
  toolUid: string,
  newOutput: any
) => {
  const client = useSupabaseClient();

  try {
    console.log("Updating panel state:", {
      panelId,
      projectId,
      toolUid,
      newOutput,
    });
    const { data, error } = await client
      .from("panelstates")
      .upsert(
        {
          panel_id: panelId,
          project_id: projectId,
          tool_uid: toolUid,
          output: newOutput,
        },
        {
          onConflict: "panel_id,project_id,tool_uid",
        }
      )
      .select()
      .single();

    if (error) throw error;
    console.log("Panel state updated successfully:", data);
    return { data, error: null };
  } catch (error) {
    console.error("Error updating panel state output:", error);
    return { data: null, error };
  }
};

 const deletePanelStates = async (
   projectId: string,
   panelIds?: string[],
   toolUid?: string
 ) => {
   const client = useSupabaseClient();

   try {
     let query = client
       .from("panelstates")
       .delete()
       .eq("project_id", projectId);

     if (panelIds && panelIds.length > 0) {
       query = query.in("panel_id", panelIds);
     }

     if (toolUid) {
       query = query.eq("tool_uid", toolUid);
     }

     const { data, error } = await query;

     if (error) throw error;
     console.log("Panel states deleted successfully:", data);
     return { data, error: null };
   } catch (error) {
     console.error("Error deleting panel states:", error);
     return { data: null, error };
   }
 };
  
const getPanelState = (
  project_id?: string,
  panel_ids?: string[],
  tool_uid?: string,
  useFetch: boolean = false
) => {
  const client = useSupabaseClient();

  if (!project_id) {
    throw new Error("Project ID is required");
  }

  let query = client
    .from("panelstates")
    .select('*')
    .eq("project_id", project_id);

  if (panel_ids && panel_ids.length > 0) {
    query = query.in("panel_id", panel_ids);
  }
  if (tool_uid) {
    query = query.eq("tool_uid", tool_uid);
  }

  if (useFetch) {
    const { data, refresh, status } = useLazyAsyncData(
      `panel-state-${project_id}-${panel_ids ? panel_ids.join(',') : "all"}-${tool_uid || "all"}`,
      async () => {
        const { data, error } = await query;
        if (error) throw error;
        return data;
      },
      {
        dedupe: "defer",
        immediate: true,
        getCachedData: (key) => {
          return useNuxtApp().payload.data[key] || null;
        },
      }
    );
    return { data, refresh, status };
  } else {
    return query;
  }
};
  

  const setupPanelWatchers = (
    panel,
    panel_id,
    tool_id,
    dataRef,
    dataKey,
    saving,
    metadata
  ) => {
    const updateState = () => {
      if (dataRef.value) {
        updatePanelStateOutput(panel_id, panel.data.project_id, tool_id, {
          [dataKey]: dataRef.value,
          metadata: metadata.value
        });
        return {
          [panel_id]: {
            ...panel.data,
            hasComponent: panel?.hasComponent,
            //TODO: consider changing to "analysis" or "output" depending on whether fileanalysis or projectanalysis.
            output: {
              [dataKey]: dataRef.value,
              metadata: metadata.value
            },
          },
        };
      }
      return null;
    };

    watch(saving, (oldVal, newVal) => {
      if (oldVal === false && newVal === true) {
        return updateState();
      }
    });

    watchDebounced(
      () => panel.data.output,
      async (newData) => {
        console.log("updating panel state", newData);
        return updateState();
      },
      { immediate: false, debounce: 500, maxWait: 1000 }
    );

    return updateState;
  };

  const hashString = (str: string) => {
    return hashSum(str)
  }

  const seededRandom = (seed: number) => {
    const x = Math.sin(seed) * 10000
    return x - Math.floor(x)
  }

  const getImageForProject = (projectTitle: string, totalImages: number=332) => {
      const hash = hashString(projectTitle)
      const seed = parseInt(hash.slice(0, 8), 16)
      const randomIndex = Math.floor(seededRandom(seed) * totalImages)
      return `/upload/image_${randomIndex}.png`
    }

  const updateProjectDetails = async (projectId: number, data: {
    title?: string
    project_location?: {
      type: string
      coordinates: number[]
    } | null
    project_timeline?: {
      phases: any[]
      start_date: string | null
      end_date: string | null
      milestones: any[]
    }
    project_scale?: number | null
    design_objectives?: string[]
    budget_level?: number | null
    additional_preferences?: Record<string, any>
    formatted_address?: string | null
    address_components?: Record<string, any> | null
  }) => {
    const client = useSupabaseClient()
    const projectStore = useProjectStore()
    
    try {
      const { data: updatedProject, error } = await client
        .from('projects')
        .update({
          title: data.title,
          project_location: data.project_location,
          project_timeline: data.project_timeline,
          project_scale: data.project_scale,
          design_objectives: data.design_objectives,
          budget_level: data.budget_level,
          additional_preferences: data.additional_preferences,
          formatted_address: data.formatted_address,
          address_components: data.address_components,
          updated_at: new Date().toISOString()
        })
        .eq('id', projectId)
        .select()
        .single()

      if (error) throw error

      // Update the project in the store
      projectStore.updateProject(projectId, updatedProject)
      
      return updatedProject
    } catch (error) {
      console.error('Error updating project details:', error)
      throw error
    }
  }

  const getProjectLocation = async (projectId: number) => {
    const client = useSupabaseClient();
    
    try {
      const { data, error } = await client
        .from('projects')  // Changed from projects_location since it's likely a view
        .select(`
          project_location,
          formatted_address
        `)
        .eq('id', projectId)
        .single();

      if (error) throw error;

      // If no location data exists, return null values
      if (!data || !data.project_location) {
        return {
          latitude: null,
          longitude: null,
          formatted_address: data?.formatted_address || null
        };
      }

      // Extract coordinates from project_location if it exists
      const coordinates = data.project_location?.coordinates || [];
      return {
        latitude: coordinates[1] || null,
        longitude: coordinates[0] || null,
        formatted_address: data.formatted_address || null
      };

    } catch (error) {
      console.error('Error fetching project location:', error);
      // Return null values if there's an error
      return {
        latitude: null,
        longitude: null,
        formatted_address: null
      };
    }
  }

  return {
    getProjectsByOwnerId,
    getProjectsByParticipantId,
    getProjectMembersByProjectId,
    getTeamMembersByGroupId,
    getAllUserProjects,
    checkUserFileAccess,
    createNewProject,
    upsertProjectAnalysis,
    getProjectAnalysis,
    upsertFileAnalysis,
    getPanelProps,
    getProjectPanelsWithAnalysis,
    getProjectPanelsWithAnalyses,
    getProjectPanelsWithFileAndProjectAnalysis,
    saveProjectLayout,
    getProjectLayouts,
    getPanelAnalysis,
    getPanelFile,
    saveProjectPanel,
    deleteProjectPanel,
    deleteProject,
    addThumbnailToLayout,
    upsertProjectData,
    savePanelStates,
    getPanelState,
    projectMenu,
    updatePanelStateOutput,
    setupPanelWatchers,
    deletePanelStates,
    getImageForProject,
    updateProjectDetails,
    getProjectLocation
  };
}