<script setup lang="ts">
import { computed } from 'vue'
import { cn } from '@/lib/utils'
import { NavigationMenuLink } from '@/components/ui/navigation-menu'

interface Props {
  title?: string
  href?: string
  icon?: string
  class?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  href: '#',
})

const linkClasses = computed(() => cn(
  'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors',
  'hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
  props.class ?? ''
))

const contentClasses = 'text-sm font-medium leading-none'
const descriptionClasses = 'line-clamp-4 text-sm leading-snug text-muted-foreground pt-2'

</script>

<template>
  <li>
    <NavigationMenuLink as-child>
      <a :href="href" :class="linkClasses">
        <div :class="{ 'flex justify-items-start': icon }">
          <Icon v-if="icon" :name="icon" class="mr-5 shrink-0" size="24" />
          <div>
            <div :class="contentClasses">{{ title }}</div>
            <p :class="descriptionClasses">
              <slot />
            </p>
          </div>
        </div>
      </a>
    </NavigationMenuLink>
  </li>
</template>
