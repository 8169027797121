import { SupabaseClient } from '@supabase/supabase-js'

// Define public paths that don't require authentication or access control
export const PUBLIC_PATHS = [
  '/',
  '/login',
  '/confirm',
  '/maintenance',
  '/register',
  '/reset-password',
  '/legal',
  '/legal/privacy',
  '/legal/tos'
] as const

interface AccessControlState {
  canCreateTeam: boolean
  isLoading: boolean
  error: Error | null
}

interface SupabaseError {
  code?: string
  message: string
  details?: string
}

interface ConfirmAccess {
  allowed: boolean
}

interface RegistrationSettings {
  registration_enabled: boolean
}

interface AccessControlResponse {
  isAllowed: boolean
  confirmAccess?: ConfirmAccess | null
  registrationSettings?: RegistrationSettings | null
  error?: SupabaseError
}

// Client-side composable
export const useAccessControl = () => {
  // Don't run on server
  if (import.meta.server) {
    return {
      canCreateTeam: ref(false),
      isLoading: ref(false),
      error: ref(null),
      checkAccess: async () => false,
      isPublicPath: (path: string) => PUBLIC_PATHS.includes(path as any)
    }
  }

  const state = reactive<AccessControlState>({
    canCreateTeam: false,
    isLoading: false,
    error: null
  })

  const client = useSupabaseClient()
  const user = useSupabaseUser()

  // Register onMounted hook before any async operations
  if (import.meta.client) {
    onMounted(() => {
      // Initialize access check
      checkAccess().then(hasAccess => {
        state.canCreateTeam = hasAccess
      })
    })
  }

  const checkAccess = async () => {
    if (!user.value?.id) return false
    
    try {
      state.isLoading = true
      
      // Use useAsyncData to cache the access check results
      const { data: accessResponse } = await useAsyncData(
        `access-check-${user.value.id}`,
        async () => {
          // Check if user is allowed access (per-user setting)
          const { data: confirmAccess, error: accessError } = await client
            .from('confirm-access')
            .select('allowed')
            .eq('user_id', user.value.id)
            .single() as { data: ConfirmAccess | null, error: SupabaseError | null }

          // If user has explicit access, allow them
          if (confirmAccess?.allowed) {
            return { isAllowed: true }
          }

          // If record not found or error, access is denied
          if (accessError?.code === 'PGRST116' || accessError) {
            return { isAllowed: false }
          }

          // Check global registration settings
          const { data: registrationSettings, error: regError } = await client
            .from('registration_settings')
            .select('registration_enabled')
            .single() as { data: RegistrationSettings | null, error: SupabaseError | null }

          // If registration settings error, access is denied
          if (regError) {
            return { isAllowed: false }
          }

          // Access is granted if both conditions are met
          return { 
            isAllowed: confirmAccess?.allowed && registrationSettings?.registration_enabled
          }
        },
        {
          // Cache for 24 hours (in seconds)
          maxAge: 86400,
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          },
          // Don't watch for reactive changes
          watch: false
        }
      )

      const hasAccess = accessResponse.value?.isAllowed ?? false
      state.canCreateTeam = hasAccess
      return hasAccess
             
    } catch (error) {
      state.error = error as Error
      console.error('Error checking access permissions:', error)
      return false
    } finally {
      state.isLoading = false
    }
  }

  // Helper to check if a path is public
  const isPublicPath = (path: string) => {
    // Check exact matches first
    if (PUBLIC_PATHS.includes(path as any)) {
      return true
    }
    // Check if the path starts with any of the public paths
    return PUBLIC_PATHS.some(publicPath => 
      publicPath !== '/' && path.startsWith(publicPath)
    )
  }

  return {
    ...toRefs(state),
    checkAccess,
    isPublicPath
  }
}

// Server-side composable
export const useServerAccessControl = (client: SupabaseClient, userId: string) => {
  // Ensure we're on the server
  if (import.meta.client) {
    throw new Error('useServerAccessControl must be used on the server')
  }

  const checkAccess = async (): Promise<AccessControlResponse> => {
    try {
      // Check if user is allowed access
      const { data: confirmAccess, error: accessError } = await client
        .from('confirm-access')
        .select('allowed')
        .eq('user_id', userId)
        .single() as { data: ConfirmAccess | null, error: SupabaseError | null }

      // Check global registration settings
      const { data: registrationSettings, error: regError } = await client
        .from('registration_settings')
        .select('registration_enabled')
        .single() as { data: RegistrationSettings | null, error: SupabaseError | null }

      // Both conditions must be true for access to be allowed
      const isAllowed = confirmAccess?.allowed === true && registrationSettings?.registration_enabled === true

      return {
        isAllowed,
        confirmAccess,
        registrationSettings,
        error: accessError || regError || undefined
      }
    } catch (error) {
      console.error('Error checking access permissions:', error)
      return {
        isAllowed: false,
        error: error as SupabaseError
      }
    }
  }

  // Helper to check if a path is public
  const isPublicPath = (path: string) => {
    // Check exact matches first
    if (PUBLIC_PATHS.includes(path as any)) {
      return true
    }
    // Check if the path starts with any of the public paths
    return PUBLIC_PATHS.some(publicPath => 
      publicPath !== '/' && path.startsWith(publicPath)
    )
  }

  return {
    checkAccess,
    isPublicPath
  }
} 