<template>
  <div vaul-drawer-wrapper class="h-full
  "
  id="custom-scroller"
  ref="rootPageRef">
    <NuxtLoadingIndicator />
    <!-- <GlobalBanner /> -->
    

    <!-- vert hoz centered loading icon  -->
    <!-- <div class="flex items-center justify-center h-[100vh] w-[100vw] fixed left-0 top-0 z-0">
      <Icon name="eos-icons:loading" class="w-10 h-10 animate-spin" />
    </div> -->
    <div ref="scrollContainerRef" :class="['scroll-container h-screen relative overflow-x-hidden bg-background !w-full', {'pb-0': !route.params.team && ready, 'pt-[64px]': visibleBanners?.length || route.path.includes('team') || route.name=='type-team-project', '!overflow-y-hidden': route.params.project || route.path.includes('team')}]" style="scrollbar-gutter: stable !important; scrollbar-color: #000 !important; scrollbar-width: thin !important;">
      <ClientOnly> 
        <div v-if="isNavbarVisible && !isAuthLayout" class="fixed top-0 left-0 right-0 w-full z-30" :class="{'bg-background': route.params.project}">
          
          <NavbarHeader ref="navbarHeader" v-motion-fade 
            :class="[
              'header-root transition-all duration-300 ease-in-out',
              {
                'header-expanded': isHeaderExpanded && route.path === '/',
                'header-collapsed': !isHeaderExpanded && route.path === '/',
                'header-default': route.path !== '/'
              }
            ]" :scrollY="scrollY">
            <template #menu>
              <div class="w-full">
                <NavbarMenu :scrollY="scrollY" :menuItems="menu" />
              </div>
            </template>

            <template #dropdownmenu>
              <NavbarDropdownMenu v-if="user" />

            </template>
          </NavbarHeader>
        </div>
      </ClientOnly>
      <NuxtLayout class="z-20 "> 
        <!-- <ClientOnly> -->
          <div class=" max-h-[100vh] w-full" :class="{'max-w-screen mx-auto': route.path==='/'}">
            <NuxtPage :scrollY="scrollY" />
          </div>
          
        <!-- </ClientOnly> -->
        
      </NuxtLayout>
      
      
      <div class="relative z-[21] h-[0px] bg-transparent w-full pointer-events-none" :style="{ paddingBottom: `${footerHeight}px` }">
          
      </div>
      <footer v-motion-fade-visible :delay="1000"
        ref="footerRef"
        v-if="shouldShowFooter" 
        class="fixed bottom-0 h-fit left-0 select-none z-0 w-full font-intervariable font-[200] pointer-events-none transition-colors duration-300 bg-designer dark:to-secondary opacity-0 overflow-y-auto scrollbar-none touch-pan-y pb-[min(50px,5vw)]" style="scrollbar-gutter: stable !important;"
      >
      <div class="w-full h-[200px] -mt-[100px]"></div>
        <div class="flex flex-col h-full justify-end">
          <div class="max-w-screen-xl mx-auto px-4 md:px-16 py-4 sm:py-8 sm:pb-8">
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 md:gap-24 text-background [&_a]:pointer-events-auto [&_button]:pointer-events-auto dark:text-muted-foreground text-left min-w-12">

              <!-- Product Section -->
              <div class="col-span-1">
                <!-- <h2 class="text-background font-[500] text-left text-md font-intervariable mb-6">Company</h2> -->
                 <div class="py-3">
                 <img src="/images/logos/superseeded_logo_s.webp" class="w-10 h-10 animate-spin-slow" />
                </div>
                <ul class="space-y-0 font-[400]">
                  <!-- <li><a href="#" @click.prevent="footerAlerts.about()" class="hover:text-background/50 text-sm">About us</a></li> -->
                  <li>
                    <!-- social links -->
                    <div class="flex items-center space-x-3">
                      <a href="https://www.linkedin.com/company/superseeded" target="_blank" class="text-background hover:text-background/50">
                        <Icon name="ion:logo-linkedin" class="w-4 h-4" />
                      </a>
                      <a href="https://x.com/Superseeded_ai" target="_blank" class="text-background hover:text-background/50">
                        <Icon name="ion:logo-x" class="w-3.5 h-3.5" />
                      </a>
                    </div>
                    <div class="text-xs mt-4 text-background">
                      SuperSeeded Future Pty Ltd<br/>
                      241 Brighton Road<br/>
                      Elwood VIC 3184<br/>
                      ABN 76667275774<br/>
                      ACN 667275774<br/>
                      <a href="mailto:hi@superseeded.ai" class="text-background hover:text-background/50 mt-4 inline-block hover:underline">hi@superseeded.ai</a>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Use cases Section -->
              <div class="col-span-1">
                <h2 class="text-background font-[500] text-left text-md font-intervariable mb-6">Use cases</h2>
                <ul class="space-y-0 font-[400]">
                  <li><a href="#" @click.prevent="footerAlerts.procurement()" class="text-background hover:text-background/50 text-sm">Procurement</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.selectionDiscovery()" class="text-background hover:text-background/50 text-sm">Selection & Discovery</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.contracts()" class="text-background hover:text-background/50 text-sm">Contracts</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.design()" class="text-background hover:text-background/50 text-sm">Design</a></li>
                </ul>
              </div>

              <!-- Industries Section -->
              <div class="col-span-1 mt-8 md:mt-0">
                <h2 class="text-background font-[500] text-left text-md font-intervariable mb-6">Industries</h2>
                <ul class="space-y-0 font-[400]">
                  <li><a href="#" @click.prevent="footerAlerts.growers()" class="text-background hover:text-background/50 text-sm">Growers</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.designers()" class="text-background hover:text-background/50 text-sm">Designers</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.consultants()" class="text-background hover:text-background/50 text-sm">Consultants</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.government()" class="text-background hover:text-background/50 text-sm">Government</a></li>
                </ul>
              </div>

              <!-- Free Tools Section -->
              <div class="col-span-1">
                <h2 class="text-background font-[500] text-left text-md font-intervariable mb-6">Free Tools</h2>
                <ul class="space-y-0 font-[400]">
                  <li><a href="#" @click.prevent="footerAlerts.tourPlants()" class="text-background hover:text-background/50 text-sm">Tour your plant list</a></li>
                  <li><a href="#" @click.prevent="footerAlerts.prelaunchBuilder()" class="text-background hover:text-background/50 text-sm">Landscape Soundscape</a></li>
                </ul>
              </div>

              <!-- Resources Section -->
              

       
            </div>

            <!-- Bottom Section -->
            <div class="mt-8 sm:mt-12 pt-8 text-background font-intervariable font-[200] text-xs [&_a]:pointer-events-auto [&_button]:pointer-events-auto [&_.accordion-trigger]:pointer-events-auto">
              <div class="my-4 sm:my-8 text-background font-inter tracking-loose font-[400] text-sm">
                SuperSeeded was founded on the lands of the Kulin Nations people in Naarm (Melbourne, Australia). We acknowledge the Traditional Custodians of Country throughout Australia, their continuing connection to land, water and community, and pay our respects to these Traditional Custodians, their ongoing cultures, and to Elders past, present and emerging.
              </div>
              <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 text-background">
                <span>©{{ new Date().getFullYear() }} SuperSeeded</span>
                <ul class="flex flex-wrap items-center space-x-4">
                  <li><a href="/legal/privacy" class="text-background hover:text-background/50">Privacy Policy</a></li>
                  <li><a href="/legal/tos" class="text-background hover:text-background/50">Terms of Use</a></li>
                  <ClientOnly>
                    <Accordion type="single" collapsible class="w-auto">
                      <AccordionItem value="cookies" class="border-none">
                        <AccordionTrigger class="py-2 hover:no-underline text-background hover:text-background/50 font-[200]">
                          Cookie Consent Policy
                        </AccordionTrigger>
                        <AccordionContent class="pt-0 text-background">
                          <NuxtCookieConsentPolicy />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </ClientOnly>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </footer>
    </div>

    <!--  TODO why does w-[100vw] overlap the scrollbars? -->



    <BottomDrawer />
    <ClientOnly>
      <SideDrawer v-if="windowWidth < 768" />
      <ReviewDrawer v-if="activePanel && reviewSideDrawerMode" :toggleSideDrawerMode="true" />
    </ClientOnly>



    <Notivue v-slot="notification">
      <NotivueSwipe :item="notification" class="shadow-xxl rounded-2xl m-2 mr-4">
        <Notification 
          class="notivue-custom" 
          :item="notification as any"
          :theme="colorMode.value === 'light' ? mylightTheme : myDarkTheme" 
        />
      </NotivueSwipe>
    </Notivue>

    <!-- Use a portal for the AlertDialog -->
    <ClientOnly>
      <Teleport to="body">
        
        <AlertDialog :open="showAlert" @update:open="(value: boolean) => showAlert = value">
          <AlertDialogContent class="border-1 border-muted-foreground shadow-xxl">
            <component :is="alertDialogDynamicComponent" v-if="alertConfig" />
          </AlertDialogContent>
        </AlertDialog>
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <!-- <AlertDialog v-model:open="commandDialogOpen">
        <AlertDialogContent 
          class="scale-[1.5] after:content-none after:ring-2 after:ring-primary"
          @pointerDownOutside="toggleCommandDialog(false)"
          @interactOutside="toggleCommandDialog(false)"
        >
          <PlantInput class="p-0" placeholder="Search Species" />
          <p class="mt-3 text-muted-foreground text-sm">Search 523,511 species<br /><span
              class="text-muted-foreground/50 text-xs">Source: GBIF, BIEN, Wikispecies</span></p>
        </AlertDialogContent>
      </AlertDialog> -->

    </ClientOnly>

    

  </div>

</template>

<script setup lang="ts">
const colorMode = useColorMode()
const dockStore = useDockStore()
const {activePanel} = storeToRefs(dockStore)

import { useScroll } from '@vueuse/core'
// Add scroll position tracking using VueUse

const scrollContainerRef = ref<HTMLElement | null>(null)
const { y: scrollY } = useScroll(scrollContainerRef)

useHead({
  title: 'superseeded.ai - The future of planting design and procurement'
})

const route = useRoute()
import { useOnline, onKeyStroke, useElementSize } from '@vueuse/core'
// console.log(colorMode.value)
import { AlertDialogOverlay } from 'radix-vue'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useRootPageRef } from '@/lib/utils'

import type { NotivueItem } from 'notivue'

const { rootPageRef } = useRootPageRef()

const ready = ref(false)



const setupNavbarAnimations = () => {
  nextTick(() => {
    if (footerRef.value) {
      const { stop: stopFooter } = useIntersectionObserver(
        footerRef,
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            footerRef.value?.classList.remove('opacity-0');
          } else {
            footerRef.value?.classList.add('opacity-0');
          }
        },
        { threshold: 0.5 }
      );

      onUnmounted(() => {
        // stopFooter();
      });
    }
    
    if (route.path === '/') {
      // Set initial state based on viewport width
      isHeaderExpanded.value = window.innerWidth >= 768

      // Use the heading1 ref from index.vue
      const { stop: stopHeader } = useIntersectionObserver(
        heading1,
        ([{ isIntersecting }]) => {
          isFirstHeadingVisible.value = isIntersecting
          isHeaderExpanded.value = isIntersecting && window.innerWidth >= 768
        },
        { threshold: 0.5 }
      )

      onUnmounted(() => {
        // stopHeader()
      })
    }
  })
}

onMounted(() => {
  console.log('setting up navbar animations');
  setupNavbarAnimations();
});

// Add a watcher on route.path to re-run the navbar animations when the route changes
watch(() => route.path, (newPath, oldPath) => {
  console.log('Route changed from', oldPath, 'to', newPath, '- reinitializing navbar animations');
  // Small delay to ensure DOM is updated
  nextTick(() => {
    setupNavbarAnimations();
  });
});

onNuxtReady(async () => {
  setTimeout(() => {
    ready.value = true
  }, 500)

  setupNavbarAnimations()
 // Check for invitee parameter
 const invitee = route.query.invitee
  if (invitee && typeof invitee === 'string') {
    console.log('invitee', invitee)
  }

})

const baseUrl = 'https://superseeded.ai'
const seoDescription = ref('The future of planting design and procurement for landscape architects, contractors and growers.')
const ogImageUrl = 'https://ik.imagekit.io/8qxqs9cznn/general/screens/014_cropped.png'

// SEO Configuration
useHead({
  title: 'SuperSeeded.ai - The future of planting and procurement for landscape architects, contractors and growers.',
  templateParams: {
    separator: '·'
  },
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png'
    }
  ]
})


// Add OG Image generation
defineOgImage({
  component: 'OgImage',
  props: {
    title: 'The future of planting and procurement for landscape architects, contractors and growers.',
    description: seoDescription.value,
    colorMode: colorMode.value,
    theme: '#18181B'
  },
  width: 1200,
  height: 630
})

useSeoMeta({
  title: 'SuperSeeded.ai - The future of planting and procurement for landscape architects, contractors and growers.',
  description: seoDescription.value,
  
  // Open Graph
  ogTitle: 'SuperSeeded.ai - The future of planting and procurement for landscape architects, contractors and growers.',
  ogDescription: seoDescription.value,
  ogImage: {
    url: ogImageUrl,
    width: 1200,
    height: 630,
    type: 'image/png',
  },
  ogUrl: baseUrl,
  ogType: 'website',
  ogSiteName: 'SuperSeeded.ai'
})

// Add Schema.org structured data
useSchemaOrg([
  defineWebPage({
    name: 'superseeded.ai—The future of planting design and procurement',
    description: seoDescription.value,
    image: '/images/screenshots/014_cropped.png',
    datePublished: '2025-02-20',
    dateModified: '2025-02-20'
  }),
  defineSoftwareApp({
    '@type': ['SoftwareApplication', 'WebApplication'],
    name: 'superseeded.ai Platform',
    description: 'Real-time availability, pricing and best practice advice for planting design and procurement.',
    operatingSystem: 'Web',
    applicationCategory: 'BusinessApplication',
    featureList: [
      'Real-time plant availability tracking',
      'Automated compliance checking',
      'Biosecurity risk assessment',
      'Integrated procurement workflow'
    ],
    screenshot: '/images/screenshots/014_cropped.png',
    image: '/images/screenshots/014_cropped.png',
    softwareVersion: '1.0.0'
  })
])




const user = useSupabaseUser()
const online = useOnline()

const userStore = useUserStore()
const { plan } = storeToRefs(userStore)

onKeyStroke('Escape', () => {
  toggleCommandDialog(false)
})

const { Meta_K, Ctrl_K } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.key === 'k' && (e.metaKey || e.ctrlKey))
      e.preventDefault()
  },
})

watch([Meta_K, Ctrl_K], (v) => {
  if (v[0] || v[1]){
    const routeType = route.params.type?.toString() || ''
    if(routeType !== 'team' && routeType !== 'project'){ 
      toggleCommandDialog(true)
    }
  }
})

watch(online, (val) => {
    let connection;
    if (!val === false) {
        connection = push.error({title: "You are offline", message: "Please check your internet connection and try again."})
    } else {
        connection = push.success({title: "You are online", message: "You are back online."})
        connection.clear()
        
    }
})

import {
  lightTheme,
  darkTheme,
  type NotivueTheme
} from 'notivue'

const mylightTheme: NotivueTheme = {
    ...lightTheme,
    // '--nv-warning-accent': "var(--destructive)"
}
const myDarkTheme: NotivueTheme = {
    ...darkTheme,
}

const uiStore = useUiStore()
const { menu, chatDialogOpen, windowWidth, showAlert, alertConfig, alertDialogDynamicComponent, commandDialogOpen, banners, reviewSideDrawerMode, visibleBanners } = storeToRefs(uiStore)

const { toggleChatDialog, toggleCommandDialog, toggleAlert, toggleBanner, setAlertConfig } = uiStore


// Example of adding a banner on component mount
onMounted(() => {
  // uiStore.addBanner({
  //   id: 'launch',
  //   message: 'We did it! 🎉 SuperSeeded is now open to the public.',
  //   type: 'info',
  //   dismissible: true,
  //   link: { url: '/about', text: 'Learn more' }
  // })
})


// Add this computed property for better readability
const shouldShowCookieConsent = computed(() => {
  return route.path == '/'
})

// Add this to hide the dialog when needed
onMounted(() => {
  const observer = new MutationObserver((mutations) => {
    const cookieDialog = document.getElementById('CybotCookiebotDialog')
    if (cookieDialog) {
      cookieDialog.style.display = shouldShowCookieConsent.value ? 'block' : 'none'
    }
  })

  observer.observe(document.body, {
    childList: true,
    subtree: true
  })

  // Initial check in case the dialog is already present
  const cookieDialog = document.getElementById('CybotCookiebotDialog')
  if (cookieDialog) {
    cookieDialog.style.display = shouldShowCookieConsent.value ? 'block' : 'none'
  }

  // Cleanup
  onUnmounted(() => {
    observer.disconnect()
  })
})

const llamaParserRef = ref()

// Provide the parser to child components
provide('llamaParser', computed(() => llamaParserRef.value?.useLlamaParser()))

// Add zoom prevention
useDisableZoom()

// Add these refs and composables
const footerRef = ref<HTMLElement | null>(null)
const { height: footerHeight } = useElementSize(footerRef)

// Provide the footer height to child components
provide('footerHeight', footerHeight)
provide('footerRef', footerRef)

const supabase = useSupabaseClient()
const isNavbarVisible = ref(false)

const isAuthLayout = computed(() => {
  return route.meta.layout === 'auth'
})

// Function to check auth status
const checkAuthStatus = async () => {
  try {
    const { data: { user: authenticatedUser } } = await supabase.auth.getUser()
    isNavbarVisible.value = !!authenticatedUser || route.path === '/'
  } catch (e) {
    console.error('Auth check error:', e)
    isNavbarVisible.value = route.path === '/'
  }
}

// Initial check - do this first
checkAuthStatus()

// Watch for route changes
watch(() => route.path, () => {
  checkAuthStatus()
})

// Set up auth state change listener
onMounted(() => {
  const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
    checkAuthStatus()
  })

  // Cleanup subscription
  onUnmounted(() => {
    subscription.unsubscribe()
  })
})

// Add these functions for footer alerts
const showComingSoonAlert = (section: string, details: string) => {
  setAlertConfig({
    title: `${section} - Check back soon`,
    description: details,
    confirmText: 'OK'
  })
  showAlert.value = true
}

const footerAlerts = {
  about: () => showComingSoonAlert('About Us', 'Learn more about our mission and team.'),
  procurement: () => showComingSoonAlert('Procurement', 'Advanced procurement tools and features.'),
  selectionDiscovery: () => showComingSoonAlert('Selection & Discovery', 'Enhanced selection and discovery features.'),
  contracts: () => showComingSoonAlert('Contracts', 'Smart contract management features.'),
  design: () => showComingSoonAlert('Design', 'Innovative design tools and features.'),
  growers: () => showComingSoonAlert('Growers', 'Specialized features for growers.'),
  designers: () => showComingSoonAlert('Designers', 'Tools and resources for designers.'),
  consultants: () => showComingSoonAlert('Consultants', 'Consultant-specific features.'),
  government: () => showComingSoonAlert('Government', 'Government-focused solutions.'),
  tourPlants: () => showComingSoonAlert('Plant List Tour', 'Interactive plant list tour feature.'),
  prelaunchBuilder: () => showComingSoonAlert('Landscape Soundscape', 'Immersive landscape soundscape experience.'),
  blog: () => showComingSoonAlert('Blog', 'Industry insights and updates.'),
  docs: () => showComingSoonAlert('Documentation', 'Comprehensive documentation.'),
  integrations: () => showComingSoonAlert('Email Referral Integrations', 'Email referral integration guides.'),
  caseStudy: () => showComingSoonAlert('Case Study', 'Detailed case studies.'),
  referralGuide: () => showComingSoonAlert('Newsletter Referral Guide', 'Newsletter referral best practices.'),
  marketing: () => showComingSoonAlert('Referral Marketing', 'Referral marketing strategies.'),
  aboutUs: () => showComingSoonAlert('What is SuperSeeded', 'Learn about SuperSeeded\'s vision.'),
  launch: () => showComingSoonAlert('Product Launch', 'Product launch strategies.'),
  books: () => showComingSoonAlert('Free Books', 'Educational resources.'),
  videos: () => showComingSoonAlert('Videos', 'Video tutorials and guides.')
}

const excludedPaths = [
  'login',
  'register',
  'confirm',
  'reset-password',
  'wellspring',
  'pitch',
  'settings',
  'team',
  'user',
  'legal',
  'dashboard'
]

const shouldShowFooter = computed(() => {
  // Only show footer on homepage
  return route.path === '/'
})

// Add these new reactive refs
const isHeaderExpanded = ref(true)
const isFirstHeadingVisible = ref(true)
const heading1 = ref<HTMLElement | null>(null)

// Add headingMotions object for v-motion animations
const headingMotions = {
  initial: {
    opacity: 0,
    y: 100
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1500,
      delay: 500,
      ease: [0.22, 1, 0.36, 1]
    }
  }
}

// Provide headingMotions to child components
provide('headingMotions', headingMotions)

</script>



<style lang="scss">

.navbar-sticky{
  will-change: transform;
}

@media (min-width: 768px) {
  html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
    height: 100%;
    background-color: var(--background) !important;
    width: 100%;
    scrollbar-color: var(--background) !important;
    scrollbar-width: thin !important;
  }
}

.ag-text-field-input {
  &:not([aria-label="Filter Value"]) {
    padding: 10px !important;
  }
}

.ag-wrapper .ag-picker-field-wrapper .ag-picker-collapsed {
  border-width: 1px !important;
  border-color: hsl(var(--muted)) !important;
}

.Notivue__content-title, .Notivue__content-message{
    font-family: "Inter", sans-serif;
}

.Notivue__content-title{
    font-weight: 500 !important;   
    width: 75%;
}

.Notivue__content-title, .Notivue__content-message, .Notivue__content-title *{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.notivue-custom {
  .Notivue__notification {
    width: 450px;
    border-radius: 0.35rem;
    border: none !important;
    box-shadow:
            0.8px 0.5px 1.7px -4px rgba(0, 0, 0, 0.036),
            1.9px 1.2px 4px -4px rgba(0, 0, 0, 0.052),
            3.5px 2.2px 7.2px -4px rgba(0, 0, 0, 0.064),
            5.8px 3.7px 11.9px -4px rgba(0, 0, 0, 0.076),
            9.5px 6.1px 19.6px -4px rgba(0, 0, 0, 0.088),
            16.6px 10.6px 34.2px -4px rgba(0, 0, 0, 0.104),
            36px 23px 74px -4px rgba(0, 0, 0, 0.14);
  }

  .Notivue__content {
    flex: 1;
    min-width: 0;
  }

  .Notivue__content-title,
  .Notivue__content-message {
    @apply truncate;
    max-width: 100%;
    font-size: 0.9em;
  }

  .Notivue__content-title {
    font-weight: 500 !important;
    width: 100%;
  }
}

html{
    /* prevent pull to refresh */
    overscroll-behavior: none;
}

body::-webkit-scrollbar {
  width: scrollbarwidthpx;
}

body{
    /* prevent pull to refresh */
    overscroll-behavior: contain;
}

html, body {
    background-color: #f1f5f9;
    height: 100% !important;
}

/* Adjust these styles for the AlertDialog */
.v-dialog-overlay {
  z-index: 9999 !important;
}

.v-dialog-content {
  z-index: 10000 !important;
}

/* Add this to prevent scrolling when the alert is open */
body.v-dialog-open {
  overflow: hidden;
}

.no-close-button svg{
  display: none;
}

div[role="alertdialog"]:has(div:has(input)) {
  margin-top: -20vh !important;
}

/* Add this to override the default AlertDialog overlay */
[data-state="open"].fixed.inset-0.bg-background\/80 {
  background-color: transparent !important;
}

/* If you want to keep the blur effect but remove the background */
[data-state="open"].fixed.inset-0.bg-background\/80.backdrop-blur-sm {
  background-color: rgba(128, 128, 128, 0.1) !important;
  backdrop-filter: blur(8px);
}

/* Remove cookie consent related styles */
.accordion-cookies {
  [data-state="open"] > .accordion-trigger {
    @apply pb-0;
  }
  
  .accordion-trigger {
    @apply py-2 text-xs font-normal;
    
    &:hover {
      @apply no-underline;
    }
  }
  
  .accordion-content {
    @apply pt-2 pb-4 text-xs;
  }
}

/* Remove these cookie consent styles */
#CybotCookiebotDialog {
  display: none !important;
}

body:has(#app[data-hide-cookie-consent="true"]) #CybotCookiebotDialog {
  display: none !important;
}

// Update header styles to use CSS transitions
.header-root {
  transition: all 0.3s ease-in-out;
  
  &.header-expanded {
    @apply h-[180px] border-b-0;
    backdrop-filter: blur(0px);
    padding: calc(4vw) calc(6vw) calc(4vw) calc(4vw);
  }

  &.header-collapsed {
    @apply h-[64px] border-b;
    backdrop-filter: blur(24px);
    padding: 0 2rem 0 4rem;
  }

  &.header-default {
    @apply h-[64px] border-b;
    backdrop-filter: blur(24px);
    
    @screen md {
      padding: 0 2rem 0 2rem;
    }
    
    @screen sm {
      padding: 0 1rem;
    }
  }
}

// Mobile styles
@media (max-width: 767px) {
  .header-root {
    @apply h-[64px] border-b-0;
    backdrop-filter: blur(0px);
    padding: 0 1rem;

    &.header-collapsed {
      @apply border-b;
      backdrop-filter: blur(24px);
    }
  }
}

// Add transition for footer
.footer-container {
  transition: opacity 0.3s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in ease-in-out forwards;
}


@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 10s linear infinite;
}

</style>