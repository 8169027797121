import { useSupabaseClient } from '#imports'
import { defineStore, getActivePinia } from 'pinia'

function resetAllStores() {
  const activePinia = getActivePinia();
  if (activePinia) {
    Object.entries(activePinia.state.value).forEach(([storeName, state]) => {
      // Create a temporary store definition using the current state
      const storeDefinition = defineStore(storeName, { state: () => state });
      const store = storeDefinition(activePinia);
      if (typeof store.$reset === 'function') {
        try {
          store.$reset();
        } catch (error) {
          
        }
      } else if (typeof store.$dispose === 'function') {
        store.$dispose();
      }
    });
  }
}

export const useAuth = () => {
  const userStore = useUserStore()
  const uiStore = useUiStore()
  const projectStore = useProjectStore()
  const dockStore = useDockStore()
  const client = useSupabaseClient()
  const nuxtApp = useNuxtApp()

  /**
   * Comprehensive cleanup of application state and session data
   */
  const clearAppState = () => {
    try {
      // Reset all stores using the resetAllStores function
      resetAllStores();

      // Clear all cached data in Nuxt payload
      Object.keys(nuxtApp.payload.data).forEach(key => {
        delete nuxtApp.payload.data[key]
      })

      // Clear any app-specific local storage items
      if (process.client) {
        const keysToRemove = [
          'superseeded-color-mode',
          'superseeded-auth-token',
        ]
        keysToRemove.forEach(key => localStorage.removeItem(key))
      }
    } catch (error) {
      console.error('Error during clearAppState:', error)
    }
  }

  /**
   * Unsubscribe from all realtime channels
   */
  const clearRealtimeSubscriptions = () => {
    const channels = client.getChannels()
    channels.forEach(channel => {
      client.removeChannel(channel)
    })
  }

  /**
   * Complete sign out process including state cleanup
   */
  const signOut = async () => {
    try {
      clearAppState()
      clearRealtimeSubscriptions()
      const supabase = useSupabaseClient()
      if (!supabase || !supabase.auth) {
        throw new Error('Supabase client or auth is not available.')
      }
      const { error } = await supabase.auth.signOut()
      if (error) throw error

      await navigateTo('/login')
    } catch (error) {
      console.error('Error during sign out:', error)
      // Cast notivue to any to bypass type errors
      const { push } = useNotivue() as any
      push.error({
        title: 'Sign out failed',
        message: 'There was an error signing out. Please try again.'
      })
    }
  }

  return {
    signOut,
    clearAppState,
    clearRealtimeSubscriptions
  }
} 