import { Component } from 'vue'

interface BottomDrawerConfig {
  title?: string
  description?: string
  hideCloseButton?: boolean
  component?: Component
  props?: Record<string, any>
  listeners?: Record<string, Function>
  actions?: Array<{
    label: string
    action: string
    variant?: string
    data?: any
  }>
  onAction?: (action: string, data?: any) => void
}

export const useBottomDrawer = () => {
  const uiStore = useUiStore()
  const { 
    bottomSheetOpen, 
    bottomSheetDynamicComponent, 
    bottomDrawerConfig, 
    useConfigurableBottomDrawer,
    bottomDrawerConfirmEnabled
  } = storeToRefs(uiStore)

  const { 
    toggleBottomSheet, 
    setBottomSheetDynamicComponent, 
    setBottomDrawerConfig,
    setBottomDrawerConfirmEnabled,
    setFileUploadContext
  } = uiStore

  const open = (config?: BottomDrawerConfig) => {
    if (config) {
      setBottomDrawerConfig({
        ...config,
        hideCloseButton: config.hideCloseButton ?? false
      }, true)
    }
    toggleBottomSheet(true)
  }

  const openWithComponent = (component: Component, props?: Record<string, any>) => {
    setBottomSheetDynamicComponent(markRaw(component))
    if (props) {
      setFileUploadContext(props)
    }
    toggleBottomSheet(true)
  }

  const close = () => {
    toggleBottomSheet(false)
  }

  const setConfirmEnabled = (enabled: boolean) => {
    setBottomDrawerConfirmEnabled(enabled)
  }

  const cleanup = () => {
    nextTick(() => {
      setBottomSheetDynamicComponent(null)
      setFileUploadContext({ use_cache: false })
      setBottomDrawerConfig(null)
      useConfigurableBottomDrawer.value = false
      bottomDrawerConfirmEnabled.value = false
    })
  }

  // Watch for drawer close to cleanup
  watch(() => bottomSheetOpen.value, (isOpen) => {
    if (!isOpen) {
      cleanup()
    }
  })

  return {
    // State
    isOpen: bottomSheetOpen,
    component: bottomSheetDynamicComponent,
    config: bottomDrawerConfig,
    isConfigurable: useConfigurableBottomDrawer,
    isConfirmEnabled: bottomDrawerConfirmEnabled,

    // Methods
    open,
    openWithComponent,
    close,
    setConfirmEnabled,
    cleanup
  }
} 