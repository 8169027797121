export default defineAppConfig({
  icons: {
    // Main icon used for PWA and favicon
    icon: {
      // Using ImageKit URL for the main icon
      source: 'https://ik.imagekit.io/8qxqs9cznn/general/logos/superseeded_logo.png',
      sizes: [16, 32, 64, 128, 256, 512],
      targetDir: 'icons',
    },
    // Apple touch icon
    apple: {
      // Using ImageKit URL for the Apple touch icon
      source: 'https://ik.imagekit.io/8qxqs9cznn/general/logos/superseeded_logo.png',
      sizes: [180],
      targetDir: 'icons',
    },
    // Shortcut icons
    shortcut: [
      {
        // Using ImageKit URL for the shortcut icon
        source: 'https://ik.imagekit.io/8qxqs9cznn/general/logos/superseeded_logo.png',
        sizes: [196],
        targetDir: 'icons',
      }
    ],
  }
}) 