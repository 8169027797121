
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as opengraph_45imageSd1P1Oll6CMeta } from "/vercel/path0/pages/_dir/opengraph-image.vue?macro=true";
import { default as _91project_93LbCjqER0x3Meta } from "/vercel/path0/pages/[type]/[team]/[project].vue?macro=true";
import { default as indexkzBk34yvEZMeta } from "/vercel/path0/pages/[type]/[team]/index.vue?macro=true";
import { default as AboutJ8r2jRfvwUMeta } from "/vercel/path0/pages/About.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as admin4FCB2UHG0ZMeta } from "/vercel/path0/pages/admin.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as _91_46_46_46slug_93FZaI6W8xt6Meta } from "/vercel/path0/pages/dashboard/[...slug].vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as settingsiT0n0ZleJjMeta } from "/vercel/path0/pages/dashboard/settings.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as invite_45onlyOpzxDHqaBpMeta } from "/vercel/path0/pages/invite-only.vue?macro=true";
import { default as _91_46_46_46code_93zwHawFg40cMeta } from "/vercel/path0/pages/invite/[...code].vue?macro=true";
import { default as privacyEXft7RtKWLMeta } from "/vercel/path0/pages/legal/privacy.vue?macro=true";
import { default as termspr0bbwAgY2Meta } from "/vercel/path0/pages/legal/terms.vue?macro=true";
import { default as tosiHUJIKyjjgMeta } from "/vercel/path0/pages/legal/tos.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as pitch12PBWb4QyVMeta } from "/vercel/path0/pages/pitch.vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as cancelxsDtSJrRzvMeta } from "/vercel/path0/pages/subscribe/cancel.vue?macro=true";
import { default as success98deXjElj0Meta } from "/vercel/path0/pages/subscribe/success.vue?macro=true";
import { default as indexNCtBbwqM1tMeta } from "/vercel/path0/pages/team/[id]/index.vue?macro=true";
import { default as indexgcTt69nCtOMeta } from "/vercel/path0/pages/team/[id]/settings/index.vue?macro=true";
import { default as _91id_93rRMNBxgy9nMeta } from "/vercel/path0/pages/team/[id].vue?macro=true";
import { default as teamsSR1tyULqhwMeta } from "/vercel/path0/pages/teams.vue?macro=true";
import { default as telescopicBMwfY9liuaMeta } from "/vercel/path0/pages/telescopic.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as testingSuzh51DFIKMeta } from "/vercel/path0/pages/testing.vue?macro=true";
import { default as userNoaTvvG4iQMeta } from "/vercel/path0/pages/user.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
import { default as _91_46_46_46stripe_connect_result_93y94MDdar9BMeta } from "/vercel/path0/pages/wellspring/[...stripe_connect_result].vue?macro=true";
export default [
  {
    name: "_dir-opengraph-image",
    path: "/_dir/opengraph-image",
    component: () => import("/vercel/path0/pages/_dir/opengraph-image.vue")
  },
  {
    name: "type-team-project",
    path: "/:type()/:team()/:project()",
    component: () => import("/vercel/path0/pages/[type]/[team]/[project].vue")
  },
  {
    name: "type-team",
    path: "/:type()/:team()",
    meta: indexkzBk34yvEZMeta || {},
    component: () => import("/vercel/path0/pages/[type]/[team]/index.vue")
  },
  {
    name: "About",
    path: "/About",
    component: () => import("/vercel/path0/pages/About.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/vercel/path0/pages/admin.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "dashboard-slug",
    path: "/dashboard/:slug(.*)*",
    meta: _91_46_46_46slug_93FZaI6W8xt6Meta || {},
    component: () => import("/vercel/path0/pages/dashboard/[...slug].vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    component: () => import("/vercel/path0/pages/dashboard/settings.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invite-only",
    path: "/invite-only",
    component: () => import("/vercel/path0/pages/invite-only.vue")
  },
  {
    name: "invite-code",
    path: "/invite/:code(.*)*",
    meta: _91_46_46_46code_93zwHawFg40cMeta || {},
    component: () => import("/vercel/path0/pages/invite/[...code].vue")
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    meta: privacyEXft7RtKWLMeta || {},
    component: () => import("/vercel/path0/pages/legal/privacy.vue")
  },
  {
    name: "legal-terms",
    path: "/legal/terms",
    meta: termspr0bbwAgY2Meta || {},
    component: () => import("/vercel/path0/pages/legal/terms.vue")
  },
  {
    name: "legal-tos",
    path: "/legal/tos",
    meta: tosiHUJIKyjjgMeta || {},
    component: () => import("/vercel/path0/pages/legal/tos.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "pitch",
    path: "/pitch",
    meta: pitch12PBWb4QyVMeta || {},
    component: () => import("/vercel/path0/pages/pitch.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/vercel/path0/pages/pricing.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "subscribe-cancel",
    path: "/subscribe/cancel",
    component: () => import("/vercel/path0/pages/subscribe/cancel.vue")
  },
  {
    name: "subscribe-success",
    path: "/subscribe/success",
    component: () => import("/vercel/path0/pages/subscribe/success.vue")
  },
  {
    name: _91id_93rRMNBxgy9nMeta?.name,
    path: "/team/:id()",
    meta: _91id_93rRMNBxgy9nMeta || {},
    component: () => import("/vercel/path0/pages/team/[id].vue"),
    children: [
  {
    name: "team-id",
    path: "",
    meta: indexNCtBbwqM1tMeta || {},
    component: () => import("/vercel/path0/pages/team/[id]/index.vue")
  },
  {
    name: "team-id-settings",
    path: "settings",
    component: () => import("/vercel/path0/pages/team/[id]/settings/index.vue")
  }
]
  },
  {
    name: "teams",
    path: "/teams",
    component: () => import("/vercel/path0/pages/teams.vue")
  },
  {
    name: "telescopic",
    path: "/telescopic",
    component: () => import("/vercel/path0/pages/telescopic.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  },
  {
    name: "testing",
    path: "/testing",
    meta: testingSuzh51DFIKMeta || {},
    component: () => import("/vercel/path0/pages/testing.vue")
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/vercel/path0/pages/user.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/vercel/path0/pages/welcome.vue")
  },
  {
    name: "wellspring-stripe_connect_result",
    path: "/wellspring/:stripe_connect_result(.*)*",
    meta: _91_46_46_46stripe_connect_result_93y94MDdar9BMeta || {},
    component: () => import("/vercel/path0/pages/wellspring/[...stripe_connect_result].vue")
  }
]