<template>
    <!-- https://github.com/radix-ui/primitives/issues/1272 -->
    <DropdownMenu v-if="user || route.path =='/'"  v-model:open="isOpen">
        <!-- sliding out menu -->
        <div class="group relative w-fit flex items-center justify-end flex-col">
            <!-- darkmode button -->
            <Button @click="toggleColorMode" variant="text" class="select-none -translate-x-4 sm:translate-x-4 sm:group-hover:translate-x-0 sm:group-hover:opacity-100 transition-all duration-60 rounded-full !w-14 h-12 sm:opacity-0" :class="{'sm:opacity-100 !translate-x-0': showButtons, 'sm:opacity-0 sm:translate-x-4': !showButtons}">
                <Icon v-if="currentColorMode === 'dark'" name="line-md:sunny-filled-loop-to-moon-filled-alt-loop-transition" class="w-4 h-4" />
                <Icon v-else-if="currentColorMode === 'light'" name="line-md:sunny-loop" class="w-4 h-4" />
                <Icon v-else name="ic:baseline-brightness-auto" class="w-4 h-4" />
            </Button>
        </div>
        <DropdownMenuTrigger class="cursor-pointer z-10 -ml-[1.5rem] sm:ml-0 flex items-center justify-end pl-20" @mouseover="showButtons = true" @mouseleave="showButtons = false">
            <div class="flex items-center justify-between absolute">
                
                <div v-for="i in 10" :key="i" class="masked min-w-12 min-h-12 -ml-[39px] -right-[0.3em] relative pointer-events-auto transition-all duration-300" :class="{'bg-muted': isToggled, 'bg-primary': !isToggled}"></div>

                <div v-if="plan?.product?.name" 
                    :class="[
                        'block whitespace-nowrap absolute self-center pointer-events-all transition-all duration-300',
                        isHighestPlan ? '-left-6' : (isToggled ? '-left-6' : '-left-8 opacity-0')
                    ]" 
                    @click.stop="handleToggleClick">
                    <small
                        :class="`pr-1 px-1.5 py-1 text-xs font-semibold text-right select-none pointer-events-all ${isToggled ? 'text-primary/70' : 'text-background'}`">
                        {{ plan.product.name }}
                        <Icon v-if="plan?.product?.name === 'Teams'" name="lucide:users" :class="`mx-1 relative -top-[0.1em] ${isToggled ? 'text-primary/70' : 'text-background'}`" />
                        <Icon v-else name="lucide:user" :class="`mx-1 -top-0.5 ${isToggled ? 'text-primary/70' : 'text-background'}`" />
                    </small>
                </div>
                <div v-if="plan?.product?.name && !isHighestPlan" 
                    :class="[
                        'block whitespace-nowrap absolute self-center pointer-events-all transition-all duration-300',
                        !isToggled ? 'left-6' : 'left-8 opacity-0'
                    ]"
                    @click.stop="handleToggleClick">
                    <small
                        :class="`pr-1 px-1.5 py-1 text-xs font-semibold text-right select-none pointer-events-all ${!isToggled ? 'text-background' : 'text-primary/70'}`">
                        {{ getNextPlanName(plan.product.name) }}
                        <Icon v-if="getNextPlanName(plan.product.name) === 'Teams'" name="lucide:users" :class="`mx-1 relative -top-[0.1em] ${!isToggled ? 'text-background' : 'text-primary/70'}`" />
                        <Icon v-else name="lucide:user" :class="`mx-1 -top-0.5 ${!isToggled ? 'text-background' : 'text-primary/70'}`" />
                    </small>
                </div>
                
                
                
                <Avatar v-if="user" class="hidden border-[1.5px] border-primary pointer-events-all hover:brightness-125 transition-all duration-150">
                    <!-- <AvatarImage :src="user?.picture" :alt="user?.name" /> -->
                    <AvatarFallback>
                        {{ user?.email?.charAt(0).toUpperCase()}}
                    </AvatarFallback>
                </Avatar>
            </div>
            <div v-if="user"
                :class="['pointer-events-all w-10 h-10 flex flex-cols justify-center items-center masked hover:brightness-125 transition-all duration-150', `bg-[url('https://avatar.vercel.sh/${user.email}.png')]`, {'-translate-x-20': !isToggled}]">
                <clientOnly>
                <div class="top-0 left-0 w-full h-full" v-html="avatar(user.email, 48)"/>
                </clientOnly>
                <p class="absolute text-xl text-white ">
                    {{ user?.email?.charAt(0).toUpperCase()}}
                </p>
            </div>
            <!-- <Icon v-else name="lucide:menu" class="mx-3"/> -->
        </DropdownMenuTrigger>
        <!-- m-3 -mt-1  -->
        <DropdownMenuContent 
            align="end" 
            :alignOffset="-10" 
            :sideOffset="10" 
            class="w-fit min-w-[300px] max-w-[90vw] h-fit flex flex-col justify-between p-2 rounded-2xl"
        >
            <!-- <DropdownMenuItem v-if="user">
                <DropdownMenuLabel class="font-normal flex">
                    <div class="flex flex-col space-y-1">
                        <p class="text-sm font-medium leading-none">
                            {{ user?.name }}
                        </p>
                        <div class="w-44">

                            <p class="text-xs truncate w-auto text-muted-foreground">
                                {{ user.display_name || user?.email }}
                            </p>
                        </div>
                    </div> 
                </DropdownMenuLabel>
            </DropdownMenuItem> -->


            <AccountSelectTeam @close-menu="isOpen = false" />



            <DropdownMenuLabel v-for="(item, index) in menuItem.filter((item) => !item.role || role === item.role)"
                :key="index" class="font-normal flex">
                <div v-if="item.label !== 'divider'" class="flex flex-col space-y-1 w-full">
                    <DropdownMenuItem class="cursor-pointer text-foreground hover:!bg-muted" @click="handleDropdownSelect(item.label.toLowerCase())">
                        <Icon :name="item.icon" class="mr-2 h-4 w-4" />
                        {{ item.label }}
                    </DropdownMenuItem>
                </div>
                <DropdownMenuSeparator v-else />
            </DropdownMenuLabel>


        </DropdownMenuContent>
    </DropdownMenu>
    <Button v-else variant="ghost" class="select-none">
        <NuxtLink to="/login">

        </NuxtLink>
    </Button>

</template>

<script setup lang="ts">
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import avatar from 'gradient-avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import ProTrial from '@/components/ProTrial.vue'
import { useAuth } from '@/composables/useAuth'

const UserStore = useUserStore()
const { role } = UserStore
const { plan } = storeToRefs(UserStore)
const { setUser, setPlan, setTeams } = UserStore
const uiStore = useUiStore()
const { selectedPlan } = storeToRefs(uiStore)
const { setBottomDrawerConfig } = uiStore
const { bottomSheetOpen } = storeToRefs(uiStore)

const colorMode = useColorMode()
const isDark = computed(() => colorMode.value === 'dark')

// Add a computed property to get the current color mode
const currentColorMode = computed(() => colorMode.preference)

// Update toggle function to cycle through dark, light, and system
const toggleColorMode = () => {
  if (colorMode.preference === 'dark') {
    colorMode.preference = 'light'
  } else if (colorMode.preference === 'light') {
    colorMode.preference = 'system'
  } else {
    colorMode.preference = 'dark'
  }
}

const showButtons = ref(false)
const user = useSupabaseUser()
const client = useSupabaseClient()

const route = useRoute()
const { canCreateTeam } = useAccessControl()

const { signOut } = useAuth()

const logout = async () => {
  const { error } = await client.auth.signOut()
    if (error) {
    console.error(error)
    return alert('Something went wrong !')
    }
  
}



const menuItem = computed(() => {
    if (user.value) {
        const items = []
        
        // Basic items always shown
        items.push(
            { label: 'Account', icon: 'lucide:user-2', role: 'admin' },
            { label: 'divider' },
            { label: 'Sign out', icon: 'lucide:log-out' }
        )

        // Only show admin if user has access
        if (canCreateTeam.value) {
            items.splice(1, 0, { label: 'Admin', icon: 'lucide:layout-dashboard', role: 'admin' })
        }

        return items
    } else {
        return [{ 
            label: 'Sign in',
            icon: 'lucide:log-in'
        }]
    }
})




async function handleDropdownSelect(key: string) {
    if (key === 'sign out') {
        await signOut()
        return
    } 
    
    const item = menuItem.value.find((item) => item.label.toLowerCase() === key)
    if (!item) return

    // Only allow navigation if user has access or it's a public route
    if (canCreateTeam.value || ['account', 'sign in'].includes(key)) {
        await navigateTo(`/${key}`)
    }
}

const isOpen = ref(false)
const isToggled = ref(true)

const isHighestPlan = computed(() => plan.value?.product?.name === 'Teams')

// Store the current plan name when toggling
const planNameWhenToggled = ref<string | null>(null)

// Watch for bottom drawer closing
watch(bottomSheetOpen, (isOpen) => {
    if (!isOpen && !isToggled.value) {
        // Only switch back if plan hasn't been upgraded
        if (plan.value?.product?.name === planNameWhenToggled.value) {
            isToggled.value = true
        }
    }
})

function handleToggleClick() {
    if (!isHighestPlan.value && canCreateTeam.value) {
        isToggled.value = !isToggled.value
        if (!isToggled.value) {
            planNameWhenToggled.value = plan.value?.product?.name
            setTimeout(() => {
                setBottomDrawerConfig({
                    title: '',
                    component: markRaw(ProTrial),
                    props: {
                        team: true
                    }
                })
            }, 180)
        }
    }
}

const getNextPlanName = (currentPlan: string): string => {
    const planTiers = ['Basic', 'Pro', 'Teams']
    const currentIndex = planTiers.indexOf(currentPlan)
    return currentIndex < planTiers.length - 1 ? planTiers[currentIndex + 1] : planTiers[currentIndex]
}

</script>

<style scoped>
.masked {
    mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
    mask-repeat: no-repeat;
    mask-size: 100%;
}

.masked-stretched {
    mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
}
</style>
