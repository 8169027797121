import { useProjectStore } from '@/stores/projectStore'

export default defineNuxtRouteMiddleware((to) => {
  const projectStore = useProjectStore()
  
  // Try route params first (faster)
  let newType = to.params.type === 'team' ? 'group' : 
                to.params.type === 'user' ? 'user' : null
  
  // Fall back to path checking if needed
  if (!newType) {
    newType = to.path.includes('/team/') ? 'group' :
              to.path.includes('/user/') ? 'user' : null
  }
  
  // Only update if we have a valid type and it's different
  if (newType && projectStore.owner_type !== newType) {
    projectStore.owner_type = newType
  }
}) 