import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/pages/runtime/validate.js";
import _03_45catch_45all_45global from "/vercel/path0/middleware/03.catch-all.global.ts";
import auth_45global from "/vercel/path0/middleware/auth.global.ts";
import owner_45type_45global from "/vercel/path0/middleware/owner-type.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@azure+cosmos@4.2.0_@azure+identity@4.6.0_@parcel+watcher@2.5.1_@types+node@22.12_fb5ek75gsisjxdaag2rwifh2ci/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _03_45catch_45all_45global,
  auth_45global,
  owner_45type_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "abort-navigation": () => import("/vercel/path0/middleware/abort-navigation.ts"),
  "team-access": () => import("/vercel/path0/middleware/team-access.ts"),
  team: () => import("/vercel/path0/middleware/team.ts")
}