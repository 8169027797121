<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'
import { twMerge } from 'tailwind-merge'
import { clsx } from 'clsx'

// Define the cn utility function
function cn(...inputs: any[]) {
  return twMerge(clsx(inputs))
}

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <ol
    :class="cn('flex flex-wrap items-center break-words text-sm text-muted-foreground', props.class)"
  >
    <slot />
  </ol>
</template>
