<script lang="ts" setup>
import * as z from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { Loader2 } from 'lucide-vue-next'
import { vAutoAnimate } from '@formkit/auto-animate'
import { TagsInput, TagsInputInput, TagsInputItem, TagsInputItemDelete, TagsInputItemText } from '@/components/ui/tags-input'
import { Button } from '@/components/ui/button'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'


const userStore = useUserStore()
const { setReceiveNewsletter } = userStore
const { receiveNewsletter } = storeToRefs(userStore)

const projectData = useProjectData();
const { getImageForProject } = projectData;

const headers = useRequestHeaders(["cookie"]);
const loading = ref(false)
const invitesLoading = ref(false)
const showImage = ref(false);
const schema = z.object({
  invitedUsers: z
    .array(z.string().email('Please enter a valid email address'))
    .min(1, 'Add at least one email address')
    .transform(emails => emails.filter(Boolean)),
});

const invitedUsers = ref<string[]>([])

const client = useSupabaseClient()

const {
  handleSubmit,
  meta,
  setErrors,
  setFieldValue
} = useForm({
  validationSchema: toTypedSchema(schema),
});

const props = defineProps<{
  email: string
}>()


const base_url = useRuntimeConfig().public.imagekit_base_url
const imageUrl = ref('')

onMounted(async () => {
  setReceiveNewsletter(true)
  const image = await getImageForProject(`${props.email + Math.random()}`)
  if (image) {
    imageUrl.value = `${base_url}${image}`
  }
})

let realtimeChannelNewsletterUpdate;

const getRealtimeChannel = (table: string, schema: string, callback: (record: any) => void) => {
  return client
    .channel(`${schema}:${table}`)
    .on(
      "postgres_changes",
      { event: "*", schema: schema, table: table },
      callback
    )
    .subscribe();
};

const onUpdate = (record: any) => {
  if (receiveNewsletter.value !== record.value) {
    setReceiveNewsletter(record.value)
  }
}

realtimeChannelNewsletterUpdate = getRealtimeChannel("users_waitlist", "public", onUpdate);

const {
  errorMessage,
  value,
  errors: invitedUsersError,
} = useField<string[]>("invitedUsers", {
  initialValue: [],
  label: "Invite others",
  validateOnMount: false,
  syncVModel: true,
});

const invitesSent = ref(false)

const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const send = handleSubmit(async (values) => {
  const invalidEmails = invitedUsers.value.filter(email => !validateEmail(email));
  if (invalidEmails.length > 0) {
    setErrors({
      invitedUsers: [`Invalid email${invalidEmails.length > 1 ? 's' : ''}: ${invalidEmails.join(', ')}`]
    });
    return;
  }

  invitesLoading.value = true;
  try {
    // First create invites in the waitlist
    const { data: inviteData, error: inviteError } = await useFetch('/api/users/invite', {
      method: 'POST',
      headers,
      body: {
        invited_users: values.invitedUsers
      }
    });

    if (inviteError.value) {
      throw inviteError.value;
    }

    // Then send the emails using resend
    const { data: emailData, error: emailError } = await useFetch('/api/invite/notify', {
      method: 'POST',
      headers,
      body: {
        team_name: 'SuperSeeded',
        invited_users: values.invitedUsers,
        invite_links: values.invitedUsers.map(email => ({
          link: `https://superseeded.ai/?&invitee=${email}`
        }))
      }
    });

    if (emailError.value) {
      throw emailError.value;
    }
    
    invitesSent.value = true;
    invitedUsers.value = [];
    push.success({
      title: 'Invites sent',
      message: `Thanks for inviting others!`
    });
  } catch (err) {
    console.error('error', err);
    setErrors({
      invitedUsers: ['Failed to send invites. Please try again.']
    });
  } finally {
    invitesLoading.value = false;
  }
});

const toggleSubscription = async () => {
  loading.value = true
  const receive_newsletter = !receiveNewsletter.value
  const { data: response } = await useFetch('/api/users/waitlist/toggle', {
    method: 'POST',
    headers,
    body: {
      receive_newsletter,
      email: props.email
    }
  })
  
  if (response.value?.data?.[0]?.receive_newsletter !== undefined) {
    setReceiveNewsletter(response.value.data[0].receive_newsletter)
  }
  loading.value = false
}
</script>

<template>
  <!-- Outer container -->
  <div class="relative flex flex-col md:flex-row items-start text-left md:min-h-[50vh] justify-start h-full w-full md:p-[10vw] mx-auto select-none  overflow-hidden">
    <!-- Background image div with absolute positioning -->
    <!-- <div 
      class="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat opacity-50 rounded-t-2xl sm:rounded-t-3xl md:rounded-3xl"
      :style="{ backgroundImage: imageUrl ? `url(${imageUrl})` : 'none' }"
    /> -->
    
    <!-- Content div with proper border radius -->
    <div class="relative z-10 p-2 sm:p-8 w-full bg-background/80 backdrop-blur-xl shadow-xxxl rounded-t-3xl md:rounded-3xl md:p-[5vw]">
      <div class="pb-2 text-2xl font-semibold tracking-tight transition-colors first:mt-0 flex">
        <div class="text-left leading-[1. 3em] -tracking-[0.025em] font-[350] text-2xl sm:text-4xl">
          <img src="/images/logos/superseeded_logo_small@0.5x.png" class="h-fill w-fill p-0 m-0 h-24 sm:max-h-[7.5rem] bg-transparent mb-10" />
          Get Early Access
          <Button @click="toggleSubscription()" class="tracking-normal flex mt-1 font-normal px-4 mt-5 bg-primary text-primary-foreground rounded-full">
            {{receiveNewsletter ? 'You are one the waitlist!' : "You won't hear from us again"}}
            {{receiveNewsletter ? email : ''}}
            <Icon v-if="receiveNewsletter && !loading" name="material-symbols:mark-email-read-outline" size="20"
              class="ml-3 inline" />
            <Icon v-if="!receiveNewsletter && !loading" name="material-symbols:mail-off-outline-rounded" size="20"
              class="ml-3 inline" />
            <Loader2 v-if="loading" class="w-5 h-5 ml-3 animate-spin" />
          </Button>
        </div>
      </div>
      <!-- <div class="my-6">
        <ul class="ml-6 list-disc [&>li]:mt-1">
          <li>Trusted grower-based selection</li>
          <li>Stock availability and forecasting</li>
          <li>Planting review</li>
          <li>Species discovery</li>
        </ul>
      </div> -->
      <FormField v-slot="{ componentField }" name="invitedUsers">
        <FormItem v-auto-animate class="">
          <FormControl>
            <div class="flex gap-2 sm:gap-4 items-center my-10">
              <div class="flex-1">
                <TagsInput 
                  :delimiters="[32, 13]" 
                  v-bind="componentField" 
                  :required="true" 
                  :addOnTab="true" 
                  :addOnPaste="true"
                  v-model="invitedUsers"
                  class="bg-background rounded-full"
                >
                  <TagsInputItem 
                    v-for="item in invitedUsers" 
                    :key="item" 
                    :value="item"
                    :class="[
                      'bg-muted text-foreground hover:bg-muted-foreground/10',
                      { 'border-destructive': !validateEmail(item) }
                    ]"
                  >
                    <TagsInputItemText />
                    <TagsInputItemDelete />
                  </TagsInputItem>
                  <TagsInputInput 
                    placeholder="Enter email addresses" 
                    class="text-foreground" 
                  />
                </TagsInput>
              </div>
              
              <Button 
                class="shrink-0 rounded-full h-10" 
                type="submit" 
                :variant="invitesSent && invitedUsers.length === 0 ? 'outline' : 'default'"
                :disabled="invitesLoading || invitedUsersError.length || invitedUsers.length === 0" 
                @click="send"
              >
                <template v-if="invitesLoading">
                  <Loader2 class="w-4 h-4 mr-2 animate-spin" />
                  Sending...
                </template>
                <template v-else-if="invitesSent && invitedUsers.length === 0">
                  <Icon name="solar:check-circle-outline" class="w-4 h-4 mr-2" />
                  Sent
                </template>
                <template v-else-if="invitedUsers.length === 0">
                 <span class="hidden sm:block">Send invites</span>
                 <span class="block sm:hidden">Invite</span>
                </template>
                <template v-else>
                  Send
                  <Icon name="famicons:paper-plane" class="w-5 h-5 ml-3" />
                </template>
              </Button>
            </div>
          </FormControl>
          <FormMessage class="font-light py-2 text-muted-foreground" />
        </FormItem>
      </FormField>
      <div v-if="receiveNewsletter && invitesSent" class="rounded-2xl bg-muted-foreground/10 p-4 mt-10 text-muted-foreground text-sm">
        <p class="font-light">
          Thanks for signing up! We'll notify you as soon as you can get early access.
        </p>
      </div>
    </div>
    <!-- <div
      class="flex relative flex-col -order-1 md:order-1 w-full md:flex-row md:space-x-4 lg:justify-between m-0 p-0 bg-center bg-cover bg-no-repeat bg-[url('/euc1.jpg')] h-full rounded-lg rounded-b-none md:rounded-l-none md:rounded-r-lg">
    </div> -->
    
  </div>
</template>
<style>
.tags-input {
  min-height: 80px;
}

:deep(.tags-input-item) {
  @apply bg-muted text-foreground hover:bg-muted-foreground/10;
}

:deep(.tags-input-input) {
  @apply text-foreground;
}
</style>